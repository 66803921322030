export type GiftCodeItem = {
    gift_code: string,
    email: string,
    used_count: string,
    usage_count: number,
    created_at: string,
    campaign_id: string,
    status: string
}

export type GiftCodeFormProps = {
    duration: string,
    email: string,
    template_id: string,
    name_surname: string,
    reference?: string,
    code?: string
    usage_count?: number,
    is_affiliate?: boolean
}

export const GiftCodeSubscriptionTypes = {
    '7DAYS': '7 Days',
    '14DAYS': '14 Days',
    '1MONTH': '1 Month',
    '2MONTH': '2 Months',
    '3MONTH': '3 Months',
    '6MONTH': '6 Months',
    '1YEAR': '1 Year'
}

export const getSubsTypeString = (s: keyof typeof GiftCodeSubscriptionTypes): string => {
    return GiftCodeSubscriptionTypes[s] || '';
}

export const getGiftCodeOptions = (): { label: string, value: string }[] => {
    return Object.entries(GiftCodeSubscriptionTypes).map((item => {
        return {
            value: item[0],
            label: item[1]
        }
    }))
}