import React, {useEffect} from "react";

import AllRoutes from "./routes/Routes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';

import { configureFakeBackend } from "./helpers";

// Themes
// For Default import Theme.scss
import "./assets/scss/Theme.scss";
import {Toast} from "react-bootstrap";

// For Dark import Theme-Dark.scss
// import './assets/scss/Theme-Dark.scss';

// configure fake backend
//configureFakeBackend();

const App = () => {
  return (
    <>
      <React.Fragment>
          <ToastContainer />
        <AllRoutes />
      </React.Fragment>
    </>
  );
};

export default App;
