import PageTitle from "../../components/PageTitle";
import {Button, Card, Col, Row} from "react-bootstrap";
import Table from "../../components/Table";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../redux/store";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {getEmailTemplates} from "../../redux/email-template-slice";
import {EmailTemplateItem} from "../../types/email-template";

export const EmailTemplateList = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();

    const columns = [
        {
            Header: "Type",
            accessor: "type",
            sort: true,
            search: true
        },
        {
            Header: "Title",
            accessor: "title",
            sort: true,
            search: true
        },
        {
            Header: "Default",
            accessor: "is_default",
            Cell: (item: any) => {
                return item.value ? 'True' : 'False'
            }
        },
        {
            Header: "Updated At",
            accessor: "updated_at",
            Cell: (item: any) => {
                return dayjs(item?.value).format('YYYY-MM-DD H:m:s')
            }
        },

        {
            Header: "Action",
            accessor: 'idddd',
            sort: false,
            Cell: (item: any) => {

                return <div style={{display: 'flex', gap: 10}}>
                    <Button size={'sm'} onClick={() => {
                        return navigate(`/email-templates/${item?.row?.original?.id}`, {
                            state: {
                                template: item?.row?.original
                            }
                        })
                    }}>Edit</Button>

                </div>
            }
        }
    ];


    useEffect(() => {
        dispatch(getEmailTemplates())
    }, [])

    const {data, loading, error} = useSelector(
        (state: RootState) => state.EmailTemplate.templates
    );

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "", path: "/email-templates"},
                ]}
                title={"Email Templates"}
            />

            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col style={{gap: 10}} className={'d-flex align-content-end justify-content-end'}><Button onClick={() => {
                                    navigate('/email-templates/create')
                                }}>Add New Template</Button></Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <h4 className="header-title">Email Template List</h4>
                            <p className="text-muted fs-14 mb-4">
                                A simple example of table with pagination and column sorting
                            </p>

                            <Table
                                isSearchable={true}
                                columns={columns}
                                data={Array.isArray(data) ? data : []}
                                pageSize={20}
                                isSortable={true}
                                pagination={true}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};