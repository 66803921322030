import { APICore, serviceEndpoints } from "../helpers/api/apiCore";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseSliceState } from "./base-slice-state";
import { GiftCodeItem } from "../types/gift-code";

export type GiftCodeSliceProps = {
    codes: BaseSliceState<GiftCodeItem[]>,

}
const INIT_STATE: GiftCodeSliceProps = {
    codes: {
        data: []
    },
};


const apiCore = new APICore();
const getGiftCodes = createAsyncThunk<GiftCodeItem[]>(
    'giftCode/list',
    async (_, thunkAPI) => {
        return apiCore.get( serviceEndpoints.giftCodeList).then(it => it.data?.data)
    }
)

const giftCodeSlice = createSlice({
    name: 'emailTemplateSlice',
    initialState: INIT_STATE,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getGiftCodes.pending, (state) => {
            state.codes.loading = true;
            state.codes.error = undefined;
        })
        builder.addCase(getGiftCodes.fulfilled, (state, {payload}) => {
            state.codes.loading = false
            state.codes.data = payload;
        })
        builder.addCase(getGiftCodes.rejected, (state, {error}) => {
            state.codes.error = error.message
            state.codes.loading = false
        })

    }
})

export default giftCodeSlice.reducer;


export {
   getGiftCodes
}
export const {
} = giftCodeSlice.actions