import PageTitle from "../../components/PageTitle";
import { Button, Card, Col, Row } from "react-bootstrap";
import Table from "../../components/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { getGiftCodes } from "../../redux/gift-code-slice";
import { ShowQRModal } from "./show-qr-modal";

export const GiftCodeList = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [code, setCode] = useState<string | undefined>(undefined)

    const columns = [
        {
            Header: "Code",
            accessor: "code",
            sort: true,
            search: true
        },
        {
            Header: "Used Count",
            accessor: "used_count",
            sort: true,
            search: true
        },
        {
            Header: "Email",
            accessor: "email",
            sort: true,
            search: true
        },
        {
            Header: "Actions",
            accessor: "aa",
            sort: true,
            search: true,
            Cell: (item: any) => {
                return <Button onClick={() => {
                    console.log(item)
                    setCode(item.row?.original?.code || undefined)
                }}>Show QR</Button>
            }
        },
    ];


    useEffect(() => {
        dispatch(getGiftCodes())
    }, [])


    const {data, loading, error } = useSelector(
        (state: RootState) => state.GiftCode.codes
    );
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "Gift Codes", path: "/gift-codes"},
                ]}
                title={"Gift Codes"}
            />

            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col className={'d-flex align-content-end justify-content-end'}><Button onClick={() => {
                                    navigate('/gift-codes/create-special')
                                }} variant={'dark'} >Add Special Code</Button> &nbsp; <Button onClick={() => {
                                navigate('/gift-codes/create')
                                }}>Add New Code</Button></Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <h4 className="header-title">Gift Code List</h4>
                            <p className="text-muted fs-14 mb-4">
                                A simple example of table with pagination and column sorting
                            </p>

                            <Table
                                isSearchable={true}
                                columns={columns}
                                data={data || []}
                                pageSize={20}
                                isSortable={true}
                                pagination={true}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ShowQRModal show={code !== undefined} onClose={() => setCode(undefined)} code={code || ''}/>
        </>
    );
};