import {APICore, PaginationResponse, serviceEndpoints} from "../helpers/api/apiCore";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {BasePaginatedSliceState, BaseSliceState} from "./base-slice-state";
import {
    ActionTypeFromProps,
    ActionTypeItem,
    CategoryFormProps,
    CategoryItem,
    ContentFormProps,
    ContentItem
} from "../types/Content";
import config from "../config";

export type ContentSliceProps = {
    actionTypes: BasePaginatedSliceState<ActionTypeItem[]>,
    categories: BasePaginatedSliceState<CategoryItem[]>,
    contents: BasePaginatedSliceState<ContentItem[]>,
    actionTypeCreating: boolean,
    actionTypeUpdating: boolean
    categoryCreating: boolean,
    categoryUpdating: boolean
    contentCreating: boolean,
    contentUpdating: boolean,
    selectedContent: BaseSliceState<ContentItem>,
    selectedContentLoading: boolean,
}
const INIT_STATE: ContentSliceProps = {
    contents: {
        data: []
    },
    categories: {
        data: []
    },
    actionTypes: {
        data: []
    },
    actionTypeCreating: false,
    actionTypeUpdating: false,
    contentCreating: false,
    contentUpdating: false,
    categoryCreating: false,
    categoryUpdating: false,
    selectedContent: {
        data: undefined
    },
    selectedContentLoading: false
};

const apiCore = new APICore();
const contentUrl = config.CONTENT_API_URL;
const getActionTypes = createAsyncThunk<PaginationResponse<ActionTypeItem>>(
    'content/action-type-list',
    async (_, thunkAPI) => {
        return apiCore.get(contentUrl + serviceEndpoints.actionTypeList).then(it => it.data)
    }
)

const updateActionType = createAsyncThunk<void, {
    id: string,
    formData: ActionTypeFromProps
}>(
    'actionTypes/update',
    async (props) => {
        return apiCore.updatePatch(contentUrl + serviceEndpoints.actionTypeUpdate.replace(':id', props.id), props.formData).then(it => it.data)
    }
)
const createActionType = createAsyncThunk<void, ActionTypeFromProps>(
    'actionTypes/create',
    async (props) => {
        return apiCore.create(contentUrl + serviceEndpoints.actionTypeCreate, props).then(it => it.data)
    }
)

const getCategories = createAsyncThunk<PaginationResponse<CategoryItem>>(
    'content/category-list',
    async (_, thunkAPI) => {
        // @ts-ignore
        return await apiCore.get(contentUrl + serviceEndpoints.categoryList).then(it => it.data);

    }
)

const updateCategory = createAsyncThunk<void, {
    id: string,
    formData: CategoryFormProps
}>(
    'category/update',
    async (props) => {
        return apiCore.updatePatch(contentUrl + serviceEndpoints.categoryUpdate.replace(':id', props.id), props.formData).then(it => it.data)
    }
)
const createCategory = createAsyncThunk<void, CategoryFormProps>(
    'category/create',
    async (props) => {
        return apiCore.create(contentUrl + serviceEndpoints.categoryCreate, props).then(it => it.data)
    }
)
const getContents = createAsyncThunk<PaginationResponse<ContentItem>, Record<string, string>|undefined>(
    'content/list',
    async (_, thunkAPI) => {
        let url = contentUrl + serviceEndpoints.contentList;
        if (_?.category_id != undefined) {
            url += `&category_id=${_?.['category_id']}`
        }
        // @ts-ignore
        return await apiCore.get(url).then(it => {
            return it.data
        });

    }
)

const sortCategories = createAsyncThunk<void, string[]>(
    'category/sort',
    async (data) => {
        return await apiCore.create(contentUrl + serviceEndpoints.categorySort, {
            categories: data
        }).then(it => {
            return it.data
        });
    }
)
type SortContentProps = {
    category_id: string,
    contents: string[]
}
const sortContents = createAsyncThunk<void,SortContentProps>(
    'category/content/sort',
    async (data) => {
        return await apiCore.create(contentUrl + serviceEndpoints.contentSelfSort, data).then(it => {
            return it.data
        });
    }
)

const getContentById = createAsyncThunk<ContentItem, string>(
    'content/byid',
    async (_, thunkAPI) => {
        // @ts-ignore
        return await apiCore.get(contentUrl + serviceEndpoints.contentDetail.replace(':id', _)).then(it => {
            return it.data
        });

    }
)


const updateContent = createAsyncThunk<void, {
    id: string,
    formData: ContentFormProps
}>(
    'content/update',
    async (props) => {
        return apiCore.updatePatch(contentUrl + serviceEndpoints.contentUpdate.replace(':id', props.id), props.formData).then(it => it.data)
    }
)
const createContent = createAsyncThunk<void, ContentFormProps>(
    'content/create',
    async (props) => {
        return apiCore.create(contentUrl + serviceEndpoints.contentCreate, props).then(it => it.data)
    }
)


const contentsSlice = createSlice({
    name: 'contentSlice',
    initialState: INIT_STATE,
    reducers: {
        resetActionTypes: state => {
            state.actionTypes.data = []
        }
    },
    extraReducers: builder => {
        builder.addCase(getActionTypes.pending, (state) => {
            state.actionTypes.loading = true;
            state.actionTypes.error = undefined;
        })
        builder.addCase(getActionTypes.fulfilled, (state, {payload}) => {
            state.actionTypes.loading = false
            state.actionTypes.data = payload.data;
        })
        builder.addCase(getActionTypes.rejected, (state, {error}) => {
            state.actionTypes.error = error.message
            state.actionTypes.loading = false
        })
        builder.addCase(getCategories.pending, (state) => {
            state.categories.loading = true;
            state.categories.error = undefined;
        })
        builder.addCase(getCategories.fulfilled, (state, {payload}) => {
            state.categories.loading = false
            state.categories.data = payload.data as CategoryItem[];
        })
        builder.addCase(getCategories.rejected, (state, {error}) => {
            state.categories.error = error.message
            state.categories.loading = false
        })
        builder.addCase(createCategory.pending, (state) => {
            state.categories.error = undefined;
            state.categoryCreating = true
        })
        builder.addCase(createCategory.fulfilled, (state, {payload}) => {
            state.categories.error = undefined;
            state.categoryCreating = false
        })
        builder.addCase(createCategory.rejected, (state, {error}) => {
            state.categories.error = undefined;
            state.categoryCreating = false
        })
        builder.addCase(updateCategory.pending, (state) => {
            state.categories.error = undefined;
            state.categoryUpdating = true
        })
        builder.addCase(updateCategory.fulfilled, (state, {payload}) => {
            state.categories.error = undefined;
            state.categoryUpdating = false
        })
        builder.addCase(updateCategory.rejected, (state, {error}) => {
            state.categories.error = undefined;
            state.categoryUpdating = false
        })

        builder.addCase(createActionType.pending, (state) => {
            state.categories.error = undefined;
            state.actionTypeCreating = true
        })
        builder.addCase(createActionType.fulfilled, (state, {payload}) => {
            state.categories.error = undefined;
            state.actionTypeCreating = false
        })
        builder.addCase(createActionType.rejected, (state, {error}) => {
            state.categories.error = undefined;
            state.actionTypeCreating = false
        })
        builder.addCase(updateActionType.pending, (state) => {
            state.categories.error = undefined;
            state.actionTypeUpdating = true
        })
        builder.addCase(updateActionType.fulfilled, (state, {payload}) => {
            state.categories.error = undefined;
            state.actionTypeUpdating = false
        })
        builder.addCase(updateActionType.rejected, (state, {error}) => {
            state.categories.error = undefined;
            state.actionTypeUpdating = false
        })

        builder.addCase(updateContent.pending, (state) => {
            state.contents.error = undefined;
            state.contentUpdating = true
        })
        builder.addCase(updateContent.fulfilled, (state, {payload}) => {
            state.contents.error = undefined;
            state.contentUpdating = false
        })
        builder.addCase(updateContent.rejected, (state, {error}) => {
            state.contents.error = undefined;
            state.contentUpdating = false
        })

        builder.addCase(createContent.pending, (state) => {
            state.contents.error = undefined;
            state.contentCreating = true
        })
        builder.addCase(createContent.fulfilled, (state, {payload}) => {
            state.contents.error = undefined;
            state.contentCreating = false
        })
        builder.addCase(createContent.rejected, (state, {error}) => {
            state.contents.error = undefined;
            state.contentCreating = false
        })

        builder.addCase(getContents.pending, (state) => {
            state.contents.error = undefined;
            state.contents.loading = true
        })
        builder.addCase(getContents.fulfilled, (state, {payload}) => {
            state.contents.error = undefined;
            state.contents.data = payload.data
            state.contents.loading = false
        })
        builder.addCase(getContents.rejected, (state, {error}) => {
            state.contents.error = undefined;
            state.contents.loading = false

        })
        builder.addCase(getContentById.pending, (state) => {
            state.selectedContent.data = undefined;
            state.selectedContent.loading = true
            state.selectedContent.error = undefined

        })
        builder.addCase(getContentById.fulfilled, (state, {payload}) => {
            state.contents.error = undefined;
            state.selectedContent.data = payload
            state.selectedContentLoading = false
        })
        builder.addCase(getContentById.rejected, (state, {error}) => {
            state.selectedContent.data = undefined;
            state.selectedContent.loading = false
            state.selectedContent.error = error.code
        })

    }
})

export default contentsSlice.reducer;


export {
    getActionTypes,
    updateActionType,
    createActionType,
    createCategory,
    updateCategory,
    getCategories,
    getContents,
    createContent,
    updateContent,
    getContentById,
    sortCategories,
    sortContents
}
export const {
    resetActionTypes,
} = contentsSlice.actions