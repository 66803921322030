// @ts-nocheck
// import PageTitle from "../../components/PageTitle";
import React, {useEffect, useState, Fragment} from "react";
import * as ReactBeautifulDnd from 'react-beautiful-dnd'
import styled from 'styled-components'
import PageTitle from "../../components/PageTitle";
import {getCategories, getContents, sortContents} from "../../redux/contents-slice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../redux/store";
import {Button, Card, Col, Row} from "react-bootstrap";
import {APICore, serviceEndpoints} from "../../helpers/api/apiCore";
import {useNavigate} from "react-router-dom";

const {DragDropContext, Draggable, Droppable} = ReactBeautifulDnd;

const ITEM_TYPES = {
    CARD: "card",
    TASK: "task"
};

const DATASET = {
    tasks: {
        "task-1": {id: "task-1", content: "water plants"},
        "task-2": {id: "task-2", content: "buy oat milk"},
        "task-3": {id: "task-3", content: "build a trello board"},
        "task-4": {id: "task-4", content: "have a beach day"},
        "task-5": {id: "task-5", content: "build tic tac toe"}
    },
    cards: {
        "card-1": {
            id: "card-1",
            title: "Home Todos",
            contents: ["task-1", "task-2"]
        },
        "card-2": {
            id: "card-2",
            title: "Work Todos",
            contents: ["task-3"]
        },
        "card-3": {id: "card-3", title: "Fun Todos", contents: ["task-4"]},
        "card-4": {id: "card-4", title: "Completed", contents: ["task-5"]}
    },
    cardOrder: ["card-1", "card-2", "card-3", "card-4"]
};

const Container = styled.div`
  margin: 2em;
  display: flex;
  @media (max-width: 720px) {
    flex-direction: column;
  }
  align-items: center;
  justify-items: center;
`;
const Menu = styled.div`
  margin: 2em;
  display: flex;
  flex-direction: column;
`;
const Note = styled.div`
  font-size: 0.8em;
  margin: 20px 0;
`;
const NewCard = styled.div`
  font-size: 1em;
  color: grey;
  min-width: 100px;
  text-align: center;
  cursor: pointer;
`;
export const ContentSort = () => {
    const [dataset, _] = useState(() => {
        return DATASET;
    });


    const dispatch: AppDispatch = useDispatch<AppDispatch>()


    const [tasks, setTasks] = useState({});
    const [cards, setCards] = useState([]);

    useEffect(() => {
        dispatch(getCategories())
    }, [])


    const {data, loading, error} = useSelector(
        (state: RootState) => state.Content.categories
    );

    useEffect(() => {
        if (!cards.length && data.length > 0) {

            setCards(data)
        }
    }, [data])
    const navigate = useNavigate()

    const saveOrder = () => {
        const sendData = cards.map(card => {
            return {
                category_id: card.id,
                contents: card.contents.map(it => it.id)
            }
        })
        dispatch(sortContents(sendData)).unwrap().then(() => {
            dispatch(getCategories())
            dispatch(getContents())
            navigate('/contents')
        });
    }

    return (

        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "Contents", path: "/contents"},
                    {label: 'Create Category', path: `/contents/categories/create`},

                ]}
                title={"Sort Categories"}
            />
            <Card>
                <Card.Header>
                    <Row>
                        <Col className={'d-flex align-content-end justify-content-end gap-2'}>
                            <Button onClick={() => {
                                saveOrder();
                            }}>Save</Button></Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Container>
                        <DragDropCards
                            cards={cards}
                            tasks={tasks}
                            setCards={setCards}
                            setTasks={setTasks}
                        />
                    </Container>
                </Card.Body>
            </Card>
        </>
    );
}

const CardsContainer = styled.div`
  margin: 2em;
  display: flex;
  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

function DragDropCards({
                           cards,
                           tasks,
                           setCards,
                           setTasks,
                           setCardOrder
                       }) {
    const [editing, setEditing] = useState(null);


    const onDragEnd = (result) => {
        const {destination, source, draggableId, type} = result;

        if (
            !destination ||
            (destination.droppableId === source.droppableId &&
                destination.index === source.index)
        ) {
            return;
        }

        if (type === ITEM_TYPES.CARD) {
            reorderCards(source, destination, draggableId);
        } else {
            // type === tasks
            const start = cards.find(it => it.id === source.droppableId)
            const finish = cards.find(it => it.id === destination.droppableId)
            if (start.id === finish.id) {
                reorderTasksWithinCard(
                    start,
                    source.index,
                    destination.index,
                    draggableId
                );
            } else {
                moveTask(start, finish, source.index, destination.index, draggableId);
            }
        }
    };

    const reorderCards = (source, destination, draggableId) => {
        const newCards = [...cards]
        const item = cards[source.index];
        newCards.splice(source.index, 1);
        newCards.splice(destination.index, 0, item);
        setCards(newCards);
    };

    const reorderTasksWithinCard = (
        card,
        sourceIdx,
        destinationIdx,
    ) => {
        const newTaskIds = [...card.contents];
        const item = newTaskIds[sourceIdx];
        newTaskIds.splice(sourceIdx, 1);
        newTaskIds.splice(destinationIdx, 0, item);
        setCards(cards.map(it => {
            if (it.id === card.id) {
                return {
                    ...card,
                    contents: newTaskIds
                }
            }
            return it
        }))

    };

    const moveTask = (start, finish, sourceIdx, destinationIdx, draggableId) => {
        const startTaskIds = [...start.contents];
        const item = start.contents[sourceIdx];
        startTaskIds.splice(sourceIdx, 1);
        const newStart = {
            ...start,
            contents: startTaskIds
        };
        const finishTaskIds = [...finish.contents]
        finishTaskIds.splice(destinationIdx, 0, item);
        const newFinish = {
            ...finish,
            contents: finishTaskIds
        };
        setCards(cards.map(it => {
            if (it.id === newStart.id) {
                return {
                    ...newStart
                }
            } else if (it.id === newFinish.id) {
                return {
                    ...newFinish
                }
            } else {
                return it;
            }
        }))
    };


    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="all-cards" direction="horizontal" type="card">
                {(provided) => (
                    <CardsContainer {...provided.droppableProps} ref={provided.innerRef}>
                        {cards.map((card, index) => {
                            return (
                                <CardSorter
                                    key={card.id}
                                    card={card}
                                    tasks={card.contents}
                                    index={index}
                                    onFocusClick={() => onFocusClick(card.id)}
                                    onTitleDoubleClick={() => setEditing(card.id)}
                                    onTaskDoubleClick={(task) => setEditing(task.id)}
                                    isTitleEditing={false}
                                    isTaskEditing={false}
                                />
                            );
                        })}
                        {provided.placeholder}
                    </CardsContainer>
                )}
            </Droppable>
        </DragDropContext>
    );
}

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Title = styled.h3`
  padding: 8px;
  font-size: 1.5em;
  text-overflow: ellipsis;
`;
const Cross = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  text-align: right;
  color: grey;
`;
const CardContainer = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  width: 220px;
  display: flex;
  flex-direction: column;
  background-color: white;
`;
const TaskList = styled.div`
  padding: 8px;
  background-color: ${(props) =>
          props.isDraggingOver ? "skyblue" : "inherit"};
  min-height: 100px;
  height: 100%;
`;
const NewTaskBar = styled.div`
  display: flex;
`;
const NewTaskButton = styled.div`
  padding: 8px;
  margin: 8px;
  cursor: pointer;
  text-align: right;
  color: grey;
`;

function CardSorter(props) {
    const [isAddingNewTask, setIsAddingNewTask] = useState(false);
    const onSaveTask = (content) => {
        if (content.trim() !== "") {
            props.onAddNewTask(content);
        }
        setIsAddingNewTask(false);
    };

    return (
        <Draggable draggableId={props.card.id} index={props.index}>
            {(provided) => (
                <CardContainer
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    id={props.card.id}
                >
                    <TitleBar>
                        <Title
                            onDoubleClick={props.onTitleDoubleClick}
                            {...provided.dragHandleProps}
                        >
                            {props.card.name}
                        </Title>
                        <Cross onClick={props.onRemoveCard}>x</Cross>
                    </TitleBar>
                    <Droppable droppableId={props.card.id} type="task">
                        {(provided, snapshot) => (
                            <Fragment>
                                <TaskList
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    isDraggingOver={snapshot.isDraggingOver}
                                >
                                    {props.tasks.map((task, index) => (
                                        <Task
                                            key={task.id}
                                            task={task}
                                            index={index}
                                            onSaveTaskEdit={(content) =>
                                                props.onSaveTaskEdit(task.id, content)
                                            }
                                            onTaskDoubleClick={() => props.onTaskDoubleClick(task)}
                                            isTaskEditing={false}
                                        />
                                    ))}
                                </TaskList>
                                {provided.placeholder}
                            </Fragment>
                        )}
                    </Droppable>
                </CardContainer>
            )}
        </Draggable>
    );
}

const TaskContainer = styled.div`
  display: flex;
`;
const TaskContent = styled.div`
  border: 1px solid lightgrey;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 2px;
  background-color: ${(props) => (props.isDragging ? "lightgreen" : "white")};
  width: 100%;
`;

function Task(props) {
    return (
        <TaskContainer>
            <Draggable draggableId={props.task.id} index={props.index}>
                {(provided, snapshot) => (
                    <TaskContent
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        isDragging={snapshot.isDragging}
                        onDoubleClick={props.onTaskDoubleClick}
                    >
                        {props.task.title}
                    </TaskContent>
                )}
            </Draggable>
        </TaskContainer>
    );
}

const Input = styled.input`
  font-size: ${(props) => props.fontSize || "inherit"};
  font-family: inherit;
  margin: ${(props) => props.margin || "inherit"};
  padding: 8px;
  width: 100%;
`;

function EditInput(props) {
    const [val, setVal] = useState(props.value);
    return (
        <Input
            type="text"
            autoFocus
            value={val}
            onChange={(e) => setVal(e.target.value)}
            onKeyPress={(event) => {
                if (event.key === "Enter" || event.key === "Escape") {
                    props.onSave(val);
                    event.preventDefault();
                    event.stopPropagation();
                }
            }}
            onBlur={() => props.onSave(val)}
            fontSize={props.fontSize}
            margin={props.margin}
        />
    );
}


function genRandomID() {
    return (Math.random() + 1).toString(36).substring(7);
}

