import PageTitle from "../../components/PageTitle";
import { Button, Card, Col, Row } from "react-bootstrap";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FormInput from "../../components/FormInput";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import CustomEditor from "../../components/CustomEditor";
import { EmailTemplateForm, EmailTemplateTypes, EmailTemplateVariables } from "../../types/email-template";
import { createEmailTemplate } from "../../redux/email-template-slice";
import Select from "react-select";
import { RawHtmlEditor } from "../../components/RawHtmlEditor";


export const CreateEmailTemplate = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>()
    const history = useLocation();
    const params = useParams();
    const navigate = useNavigate()
    const data = {
        variables: ''
    }
    const [loading, setLoading] = useState<number>(0)

    const defValues = {}
    const {
        control,
        register,
        handleSubmit,
        watch,
        formState: {errors}
    } = useForm<EmailTemplateForm>({
        defaultValues: defValues,
        resolver: yupResolver(object().shape({
            title: string().label('Title').required(),
            subject: string().label('Subject').required(),
            content: string().label('Content').required(),
        }))
    })

    const typeWatch = watch('type');


    const onSubmit = (data: EmailTemplateForm) => {
        dispatch(createEmailTemplate({...data})).then(() => {
            navigate('/email-templates')
        })
    }
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "Email templates", path: "/email-templates"},
                    {
                        label: 'Email Template Create',
                        path: `/email-templates/Create`
                    },

                ]}
                title={"Email Template Detail"}
            />
            {data &&

                <Row>
                    <Col>
                        <Card>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                <Card.Body>
                                    <h4 className="header-title">Edit Email Template</h4>
                                    <p className="text-muted fs-14 mb-4"></p>
                                    <Row>
                                        <Col>
                                            <Controller name={'type'} control={control}
                                                        render={({field}) => {
                                                            return (
                                                                <Select
                                                                    onChange={(it) => {
                                                                        field.onChange(it?.value)
                                                                    }}
                                                                    options={
                                                                        Object.keys(EmailTemplateTypes).map(it => {
                                                                            console.log('it', it)
                                                                            return {
                                                                                label: it,
                                                                                value: it,
                                                                                text: it
                                                                            }
                                                                        })
                                                                    }
                                                                    placeholder="Choose a type..."
                                                                />
                                                            )
                                                        }}/>
                                        </Col>
                                        <Col>
                                            <FormInput
                                                type={'checkbox'}
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Is Default"}
                                                name="is_default"
                                                placeholder="Is Default"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col>
                                            <FormInput
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Title"}
                                                name="title"
                                                placeholder="Enter Title"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col>
                                            <FormInput
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Subject"}
                                                name="subject"
                                                placeholder="Enter Subject"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Controller
                                                control={control}
                                                name={'content'}
                                                render={({field}) => {
                                                    return (
                                                        <RawHtmlEditor
                                                            label={'Content'}
                                                            onChange={val => field.onChange(val)}
                                                            value={field.value}
                                                        />

                                                    )
                                                }}/>

                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: 50}}>

                                        <Col>
                                            Available
                                            Variables <code>{JSON.stringify(EmailTemplateVariables[typeWatch])}</code>
                                        </Col>
                                    </Row>

                                </Card.Body>
                                <Card.Footer style={{display: 'flex', justifyContent: 'flex-end', gap: 20}}>
                                    <Button href={'/email-templates'} variant={'outline-danger'}>Cancel</Button>
                                    <Button type={"submit"} disabled={loading > 0}>Save</Button>

                                </Card.Footer>
                            </form>
                        </Card>
                    </Col>
                </Row>
            }
        </>
    );
};