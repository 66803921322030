import PageTitle from "../../components/PageTitle";
import {Button, Card, Col, Row} from "react-bootstrap";
import Table from "../../components/Table";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../redux/store";
import {getUsers} from "../../redux/user-slice";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";

export const UserList = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();

    const columns = [
        {
            Header: "ID",
            accessor: "id",
            sort: true,
        },
        {
            Header: "Name Surname",
            accessor: "name_surname",
            sort: true,
            search: true
        },
        {
            Header: "Email",
            accessor: "email",
            sort: true,
            search: true
        },
        {
            Header: "Role",
            accessor: "user_type",
            sort: false,
        },
        {
            Header: "Created At",
            accessor: "created_at",
            sort: true,
            Cell: (item: any) => {
                return dayjs(item?.row?.original?.created_at).format('YYYY-MM-DD HH:mm:ss')
            }
        },
        {
            Header: "Action",
            accessor: 'idddd',
            sort: false,
            Cell: (item: any) => {

                return <div style={{display: "flex"}}><Button size={'sm'} onClick={() => {
                    return navigate(`/users/${item?.row?.original?.id}`, {
                        state: {
                            user: item?.row?.original
                        }
                    })
                }} >Edit</Button>
                </div>
            }
        }
    ];


    useEffect(() => {
        dispatch(getUsers())
    }, [])

    const {data, loading, error } = useSelector(
        (state: RootState) => state.User.users
    );
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "Users", path: "/users"},
                ]}
                title={"Users"}
            />

            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <h4 className="header-title">Pagination &amp; Sort</h4>
                            <p className="text-muted fs-14 mb-4">
                                A simple example of table with pagination and column sorting
                            </p>

                            <Table
                                isSearchable={true}
                                columns={columns}
                                data={data || []}
                                pageSize={20}
                                isSortable={true}
                                pagination={true}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};