import {APICore, serviceEndpoints} from "../helpers/api/apiCore";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {BaseSliceState} from "./base-slice-state";
import {EmailTemplateForm, EmailTemplateItem} from "../types/email-template";

export type EmailTemplateSliceProps = {
    templates: BaseSliceState<EmailTemplateItem[]>,

}
const INIT_STATE: EmailTemplateSliceProps = {
    templates: {
        data: []
    },

};

const apiCore = new APICore();
const getEmailTemplates = createAsyncThunk<EmailTemplateItem[]>(
    'emailTemplate/list',
    async (_, thunkAPI) => {
        return apiCore.get( serviceEndpoints.templateList).then(it => it.data)
    }
)

const updateEmailTemplate = createAsyncThunk<void, EmailTemplateForm>(
    'emailTemplate/update',
    async (props) => {
        const {id, ...restProps} = props;
        console.log(props);
        return apiCore.updatePatch( serviceEndpoints.templateUpdate.replace(':id', id as string), restProps).then(it => it.data)    }
)
const createEmailTemplate = createAsyncThunk<void, EmailTemplateForm>(
    'emailTemplate/create',
    async (props) => {
        return apiCore.create(serviceEndpoints.templateList, props).then(it => it.data)
    }
)
const emailTemplateSlice = createSlice({
    name: 'emailTemplateSlice',
    initialState: INIT_STATE,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getEmailTemplates.pending, (state) => {
            state.templates.loading = true;
            state.templates.error = undefined;
        })
        builder.addCase(getEmailTemplates.fulfilled, (state, {payload}) => {
            state.templates.loading = false
            state.templates.data = payload;
        })
        builder.addCase(getEmailTemplates.rejected, (state, {error}) => {
            state.templates.error = error.message
            state.templates.loading = false
        })

    }
})

export default emailTemplateSlice.reducer;


export {
 getEmailTemplates,
    createEmailTemplate,
    updateEmailTemplate
}
export const {
} = emailTemplateSlice.actions