import React, { FC } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import QRCode from "react-qr-code";
import toImg from "react-svg-to-image";

type ShowQrModalProps = {
    show: boolean,
    onClose: () => void,
    code: string
}
const downloadQR = () => {
    const canvas = document.getElementsByTagName("canvas");
    const pngUrl = canvas?.[0]
        ?.toDataURL("image/png")
        ?.replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    console.log(downloadLink);
    downloadLink.download = "123456.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};
export const ShowQRModal: FC<ShowQrModalProps> = ({show, onClose, code}) => {
    return (
        <Modal show={show} onHide={() => onClose()}>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Gift Code QR
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 10}}>
                            <QRCode
                                size={250}
                                widths={255}
                                id={'gift-qr'} value={`http://lvsports.app?lCHCf5nucFhOW0OqZbkw=${code}`} />
                            <Button variant={'primary'} onClick={() => {
                                toImg('#gift-qr', code).then((res: any) => {
                                        onClose()
                                    })
                            }}>Download</Button>

                        </Col>
                    </Row>
                </Modal.Body>
        </Modal>
    );
/*
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const {handleSubmit, control, formState: {errors}} = useForm<GiftCodeFormProps>({
        resolver: yupResolver(object().shape({
            days: number().required(),
            email: string().email().optional()
        }))
    })

    const onSubmit = (data: GiftCodeFormProps) => {
        setLoading(true)
        apiCore.create(serviceEndpoints.giftCodeList, {
            ...data,
            days: data?.days ?  Number(data.days) : undefined,
            usage_count: data?.usage_count ? Number(data?.usage_count): undefined
        }).then(() => {
            setLoading(false)
            dispatch(getGiftCodes());
            onClose()
        }).catch(() => setLoading(false))
    }
    return (
        <Modal show={show} onHide={() => onClose()}>
            <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                Add Gift Code
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group controlId="validationCustom01">
                            <Form.Label>Days</Form.Label>
                            <Controller control={control} name={'days'} render={({field}) => {
                                return (
                                    <Form.Control
                                        placeholder="365"
                                        type={'number'}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}/>
                            <Form.Control.Feedback > {errors?.days?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group controlId="validationCustom01">
                            <Form.Label>Usage Count (Optional, Default: 1)</Form.Label>
                            <Controller control={control} name={'usage_count'} render={({field}) => {
                                return (
                                    <Form.Control
                                        placeholder="1"
                                        type={'number'}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}/>
                            <Form.Control.Feedback > {errors?.usage_count?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>


                <Row style={{marginTop: 30}}>
                    <Col>
                        <Form.Group controlId="validationCustom01">
                            <Form.Label>Email (Optional)</Form.Label>
                            <Controller control={control} name={'email'} render={({field}) => {
                                return (
                                    <Form.Control
                                        placeholder="test@lvsports.app"
                                        type={'email'}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}/>
                            <Form.Control.Feedback > {errors?.email?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group controlId="validationCustom01">
                            <Form.Label>Code (Optional)</Form.Label>
                            <Controller control={control} name={'code'} render={({field}) => {
                                return (
                                    <Form.Control
                                        placeholder="XXXXXXXX"
                                        type={'number'}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}/>
                            <Form.Control.Feedback > {errors?.code?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>


            </Modal.Body>
                <Modal.Footer>
                    <Button variant={'secondary'} disabled={loading} onClick={() => onClose()}>Close</Button>
                    <Button disabled={loading}
                            onClick={handleSubmit(onSubmit)}
                            variant={'primary'}>Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>*/

}