import PageTitle from "../../components/PageTitle";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import FormInput from "../../components/FormInput";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {object, string} from "yup";
import {SportItemFormProps} from "../../types/SportItem";
import FileUploader from "../../components/FileUploader";
import {APICore} from "../../helpers/api/apiCore";
import {createSport} from "../../redux/sports-slice";
import {CategoryFormProps} from "../../types/Content";
import {createCategory} from "../../redux/contents-slice";


export const CategoryCreate = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>()
    const history = useLocation();
    const navigate = useNavigate()
    const [loading, setLoading] = useState<number>(0)


    const {control, register, setValue, handleSubmit, formState: {errors}} = useForm<CategoryFormProps>({
        defaultValues: {
        },
        resolver: yupResolver(object().shape({
            name: string().label('Name').required(),
        }))
    })

    const onSubmit = (data: CategoryFormProps) => {
        dispatch(createCategory(data)).unwrap().then(() => {
            navigate('/contents/categories')
        }).catch(() => {

        })
    }
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "Contents", path: "/contents"},
                    {label: 'Create Category', path: `/contents/categories/create`},

                ]}
                title={"Create Category"}
            />
                <Row>
                    <Col>
                        <Card>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <Card.Body>
                                <h4 className="header-title">Create Category</h4>
                                <p className="text-muted fs-14 mb-4"></p>

                                    <Row>

                                        <Col>
                                            <FormInput
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Name"}
                                                name="name"
                                                placeholder="Enter Category"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                    </Row>
                                <Row>

                                    <Col>
                                        <FormInput
                                            errors={errors}
                                            register={register}
                                            control={control}
                                            label={"Sub Title"}
                                            name="sub_title"
                                            placeholder="Enter Sub Title"
                                            containerClass={"mb-3"}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <FormInput
                                        type={'checkbox'}
                                        errors={errors}
                                        register={register}
                                        control={control}
                                        label={"Is Active"}
                                        name="is_active"
                                        placeholder="Is Active"
                                        containerClass={"mb-3"}
                                    />
                                </Row>


                            </Card.Body>
                            <Card.Footer style={{display: 'flex',justifyContent: 'flex-end', gap: 20}}>
                                <Button href={'/content/categories'} variant={'outline-danger'}>Cancel</Button>
                                <Button type={"submit"} disabled={loading > 0}>Save</Button>

                            </Card.Footer>
                        </form>
                        </Card>
                    </Col>
                </Row>

        </>
    );
};