import { FC, useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";

type RawHtmlEditorProps = {
    onChange: (text?: string) => void,
    value?: string,
    label?: string
}
export const RawHtmlEditor: FC<RawHtmlEditorProps> = (
    {
        onChange,
        value,
        label
    }) => {
    const [show, setShow] = useState(false);
    return (
        <>
            <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                {label && <label>{label}</label>}
                <textarea
                    rows={10}
                    onChange={(el) => onChange(el.target.value)}>
                    {value}
                </textarea>
                <Button variant={'primary'} onClick={() => setShow(true)}>Preview</Button>
            </div>
            <Modal show={show} onHide={() => setShow(false)} size={'xl'}>
                <Modal.Body dangerouslySetInnerHTML={{__html: value || ''}}>
                </Modal.Body>
            </Modal>
        </>
    )
}