import {string} from "yup";

export enum UserType {
    USER = 'USER',
    ADMIN = 'ADMIN',
    COACH = 'COACH'
}

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    NONE = 'NONE'
}

export type UserItem = {
    id: string,
    email?: string,
    user_type: UserType,
    name_surname: string

}

export type UserDetailItem = UserItem & {
    name_surname: string,
    sport_type?: {
        id?: string,
        title?: string
    },
    gender: Gender,
    birth_date: string
}

export type UserEditFormProps = Omit<UserDetailItem, 'sport_type' | 'name_surname' | 'id'> & {
    password?: string|null,
    sport_type_id: string
}

export type UserSubscription = {
    id: string,
    user_id: string,
    transaction_id: string,
    started_at: Date,
    expires_at: Date,
    is_cancelled: boolean,
    cancellation_date: Date,
    type: string
}

export type AddSubscriptionInfoForm = {
    user_id: string,
    start_at: string,
    expires_at: string,
    type?: string
}