import PageTitle from "../../components/PageTitle";
import {Button, Card, Col, Row} from "react-bootstrap";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {Navigate, useLocation, useNavigate, useParams} from "react-router-dom";
import FormInput from "../../components/FormInput";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {object, string} from "yup";
import {APICore, serviceEndpoints} from "../../helpers/api/apiCore";
import {Simulate} from "react-dom/test-utils";
import {CategoryFormProps} from "../../types/Content";
import {updateCategory} from "../../redux/contents-slice";
import config from "../../config";


export const CategoryDetail = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>()
    const history = useLocation();
    const params = useParams();
    const navigate = useNavigate()
    const data = history.state.category;
    const [loading, setLoading] = useState<number>(0)


    const {
        control,
        register,
        watch,
        setValue,
        handleSubmit,
        reset,
        formState: {errors, dirtyFields}
    } = useForm<CategoryFormProps>({
        defaultValues: {
            ...data,
        },
        resolver: yupResolver(object().shape({
            name: string().label('Name').required()
        }))
    })
    if (!data) {
        return <Navigate to={'/contents/categories'}/>
    }

    const onSubmit = (data: CategoryFormProps) => {
        dispatch(updateCategory({
            id: params.id as string,
            formData: data
        })).unwrap().then(() => {
            navigate('/contents/categories')
        }).catch(() => {

        })
    }
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "Contents", path: "/contents"},
                    {label: "Categories", path: "/contents/categories"},
                    {
                        label: history?.state ? history?.state?.category?.name : 'Category Detail',
                        path: `/contents/categories/${params.id}`
                    },

                ]}
                title={"Category Detail"}
            />
            {data &&

                <Row>
                    <Col>
                        <Card>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                <Card.Body>
                                    <h4 className="header-title">Edit Category Details</h4>
                                    <p className="text-muted fs-14 mb-4"></p>

                                    <Row>

                                        <Col>
                                            <FormInput
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Name"}
                                                name="name"
                                                placeholder="Enter Category Name"
                                                containerClass={"mb-3"}
                                            />
                                            <FormInput
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Sub Title"}
                                                name="sub_title"
                                                placeholder="Enter Subtitle"
                                                containerClass={"mb-3"}
                                            />
                                            <FormInput
                                                type={'checkbox'}
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Is Active"}
                                                name="is_active"
                                                placeholder="Is Active"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer style={{display: 'flex', justifyContent: 'flex-end', gap: 20}}>
                                    <Button variant={'danger'} disabled={loading > 0} onClick={() => {
                                        const apiCore = new APICore();
                                        const contentUrl = config.CONTENT_API_URL;

                                        apiCore.delete(contentUrl + serviceEndpoints.categoryDelete.replace(':id', data.id)).then(() => {
                                            setLoading(0);
                                            navigate('/contents/categories')
                                        }).catch(err => {
                                            console.log('error')
                                        })
                                    }
                                    }>Delete</Button>
                                    <Button href={'/contents/categories'} variant={'outline-danger'}>Cancel</Button>
                                    <Button type={"submit"} disabled={loading > 0}>Save</Button>

                                </Card.Footer>
                            </form>
                        </Card>
                    </Col>
                </Row>
            }
        </>
    );
};