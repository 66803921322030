import { configureStore } from '@reduxjs/toolkit'
import layoutSlice from "./layout-slice";
import authSlice from "./auth-slice";
import userSlice from "./user-slice";
import sportsSlice from "./sports-slice";
import contentsSlice from "./contents-slice";
import emailTemplateSlice from "./email-template-slice";
import giftCodeSlice from "./gift-code-slice";
import campaignsSlice from "./campaigns-slice";

export const store = configureStore({
  reducer: {
    Layout: layoutSlice,
    Auth: authSlice,
    User: userSlice,
    Sport: sportsSlice,
    Content: contentsSlice,
    EmailTemplate: emailTemplateSlice,
    GiftCode: giftCodeSlice,
    Campaign: campaignsSlice
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
