import PageTitle from "../../components/PageTitle";
import { Button, Card, Col, Row } from "react-bootstrap";
import Table from "../../components/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { ShowQRModal } from "./show-qr-modal";
import { getCampaigns } from "../../redux/campaigns-slice";
import { APICore, serviceEndpoints } from "../../helpers/api/apiCore";
import Loader from "../../components/Loader";
import { getSubsTypeString, GiftCodeSubscriptionTypes } from "../../types/gift-code";

const apiCore = new APICore()
export const CampaignList = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [code, setCode] = useState<string | undefined>(undefined)
    const [loadingGeneral, setLoading] = useState<boolean>(false)

    const sendCampaign = (id: string) => {
        setLoading(true)
        apiCore.updatePatch(serviceEndpoints.campaignSend.replace(':id', id), {}).then(() => {
            setTimeout(() => {
                setLoading(false)
                navigate('/campaigns');
            }, 2000)

        })
    }
    const columns = [
        {
            Header: "Name",
            accessor: "name",
            sort: true,
            search: true
        },
        {
            Header: "Status",
            accessor: "status",
        },
        {
            Header: "Code Count",
            accessor: "receiver_count",
            sort: true,
            search: true
        },
        {
            Header: "Used Count",
            accessor: "a",
            sort: true,
            search: true
        },
        {
            Header: "Subscription",
            accessor: "subscription_type",
            Cell: (item: any) => {
                return getSubsTypeString(item.row.original.subscription_type as keyof typeof GiftCodeSubscriptionTypes)
            }
        },
        {
            Header: "Actions",
            accessor: "aa",
            sort: true,
            search: true,
            Cell: (item: any) => {
                return (
                    <div style={{display: "flex", gap: 10}}>
                        <Button variant={'secondary'} onClick={() => {
                            navigate(`/campaigns/${item?.row?.original?.id}/codes`)
                        }}>Show Codes</Button>
                        {item.row?.original?.status === 'READY' && <Button onClick={() => {
                            sendCampaign(item.row?.original?.id)
                        }}>Send</Button> }
                    </div>
                )
            }
        },
    ];


    useEffect(() => {
        dispatch(getCampaigns())
    }, [])


    const {data, loading, error} = useSelector(
        (state: RootState) => state.Campaign.campaigns
    );
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "Campaigns", path: "/campaigns"},
                ]}
                title={"Campaigns"}
            />

            {loadingGeneral && <Loader /> }
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col className={'d-flex align-content-end justify-content-end'}><Button onClick={() => {
                                    navigate('/campaigns/create')
                                }}>Add New Campaign</Button></Col>

                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <h4 className="header-title">Campaign List</h4>
                            <p className="text-muted fs-14 mb-4">
                                A simple example of table with pagination and column sorting
                            </p>

                            <Table
                                isSearchable={true}
                                columns={columns}
                                data={data || []}
                                pageSize={20}
                                isSortable={true}
                                pagination={true}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ShowQRModal show={code !== undefined} onClose={() => setCode(undefined)} code={code || ''}/>
        </>
    );
};