import PageTitle from "../../components/PageTitle";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../redux/store";
import {Navigate, useLocation, useNavigate, useParams} from "react-router-dom";
import FormInput from "../../components/FormInput";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {object, string} from "yup";
import {APICore, serviceEndpoints} from "../../helpers/api/apiCore";
import {Simulate} from "react-dom/test-utils";
import {CategoryFormProps, ContentFormProps} from "../../types/Content";
import {getActionTypes, getCategories, getContentById, updateCategory, updateContent} from "../../redux/contents-slice";
import ReactQuill from "react-quill";
import config from "../../config";
import {ConfirmButton} from "../../components/ConfirmButton";
import CustomEditor from "../../components/CustomEditor";


export const ContentDetail = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>()
    const history = useLocation();
    const params = useParams();
    const navigate = useNavigate()
    const data = history.state.content;
    const [loading, setLoading] = useState<number>(0)

    const {data: actionTypes = [], loading: actionTypesLoading} = useSelector(
        (state: RootState) => state.Content.actionTypes
    );
    const {data: categories = [], loading: categoriesLoading, error} = useSelector(
        (state: RootState) => state.Content.categories
    );
    const {
        data: selectedContent = undefined,
        loading: selectedContentLoading,
        error: selectedContentError
    } = useSelector(
        (state: RootState) => state.Content.selectedContent
    );

    console.log('daaa', data);
    const defValues = {
        ...data,
        category_id: data?.category?.id,
        action_type_id: data?.action_type?.id,
        body: selectedContent?.body || ''
    }
    const {
        control,
        register,
        watch,
        setValue,
        handleSubmit,
        reset,
        formState: {errors, dirtyFields}
    } = useForm<ContentFormProps>({
        defaultValues: defValues,
        resolver: yupResolver(object().shape({
            title: string().label('Title').required(),
            subtitle: string(),
            body: string(),
            action_type_id: string().label('Action Type').required(),
            category_id: string().label('Action Type').required(),
            slug: string()

        }))
    })

    useEffect(() => {
        dispatch(getCategories())
        dispatch(getActionTypes())
        dispatch(getContentById(params.id || '')).unwrap().then((item) => {
            reset({
                ...defValues,
                body: item.body
            })
        })
    }, [])
    if (!data) {
        return <Navigate to={'/contents'}/>
    }

    const onSubmit = (data: ContentFormProps) => {
        dispatch(updateContent({
            id: params.id as string,
            formData: data
        })).unwrap().then(() => {
            navigate('/contents')
        }).catch(() => {

        })
    }
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "Contents", path: "/contents"},
                    {
                        label: history?.state ? history?.state?.content?.title : 'Content Detail',
                        path: `/contents/${params.id}`
                    },

                ]}
                title={"Content Detail"}
            />
            {data &&

                <Row>
                    <Col>
                        <Card>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                <Card.Body>
                                    <h4 className="header-title">Edit Content Details</h4>
                                    <p className="text-muted fs-14 mb-4"></p>

                                    <Row>

                                        <Col>
                                            <FormInput
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Title"}
                                                name="title"
                                                placeholder="Enter Title"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                        </Row>
                                    <Row>
                                        <Col>
                                            <FormInput
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Subtitle"}
                                                name="subtitle"
                                                placeholder="Sub Title"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>

                                            <Form.Group className="mb-3" controlId="validationCustom03">
                                                <Form.Label>Action Type</Form.Label>
                                                <Controller control={control}
                                                            name={'action_type_id'}
                                                            render={({field}) => {
                                                                return <Form.Select
                                                                    isInvalid={errors?.action_type_id !== undefined}
                                                                    onChange={field.onChange}
                                                                    value={field.value}
                                                                >
                                                                    <option>Choose</option>
                                                                    {
                                                                        actionTypes.map(it => {
                                                                            return (
                                                                                <option
                                                                                    selected={data.action_type_id === it.id}
                                                                                    value={it.id}>{it.name}</option>
                                                                            )
                                                                        })
                                                                    }

                                                                </Form.Select>
                                                            }}/>

                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.action_type_id?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>

                                            <Form.Group className="mb-3" controlId="validationCustom03">
                                                <Form.Label>Category</Form.Label>
                                                <Controller control={control}
                                                            name={'category_id'}
                                                            render={({field}) => {
                                                                return <Form.Select
                                                                    isInvalid={errors?.category_id !== undefined}
                                                                    onChange={field.onChange}
                                                                    value={field.value}
                                                                >
                                                                    <option>Choose</option>
                                                                    {
                                                                        categories.map(it => {
                                                                            return (
                                                                                <option
                                                                                    selected={data.category_id === it.id}
                                                                                    value={it.id}>{it.name}</option>
                                                                            )
                                                                        })
                                                                    }

                                                                </Form.Select>
                                                            }}/>

                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.category_id?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>

                                        <FormInput
                                            errors={errors}
                                            register={register}
                                            control={control}
                                            label={"Slug"}
                                            name="slug"
                                            placeholder="Enter Slug"
                                            containerClass={"mb-3"}
                                        />
                                    </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormInput
                                                type={'checkbox'}
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Force Update"}
                                                name="force_update"
                                                containerClass={"mb-3"}
                                            />
                                            <FormInput
                                                type={'checkbox'}
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Is Active"}
                                                name="is_active"
                                                placeholder="Is Active"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Controller
                                                control={control}
                                                name={'body'}
                                                render={({field}) => {
                                                    return (
                                                        <CustomEditor
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                field.onChange(e)
                                                            }}
                                                            theme={'snow'} modules={
                                                            {
                                                                toolbar: [
                                                                    [{'header': '1'}, {'header': '2'}, {'font': []}],
                                                                    [{size: []}],
                                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                                    [{'list': 'ordered'}, {'list': 'bullet'},
                                                                        {'indent': '-1'}, {'indent': '+1'}],
                                                                    ['link', 'image', 'video'],
                                                                    ['clean']
                                                                ],
                                                                clipboard: {
                                                                    // toggle to add extra line breaks when pasting HTML:
                                                                    matchVisual: false,
                                                                }
                                                            }}/>
                                                    )
                                                }}/>

                                        </Col>
                                    </Row>

                                </Card.Body>
                                <Card.Footer style={{display: 'flex', justifyContent: 'flex-end', gap: 20}}>
                                    <ConfirmButton
                                        variant={'danger'} disabled={loading > 0} onOk={() => {
                                        const contentUrl = config.CONTENT_API_URL;
                                        const apiCore = new APICore();
                                        return apiCore.delete(contentUrl + serviceEndpoints.contentDelete.replace(':id', data.id)).then(() => {
                                            setLoading(0);
                                            navigate('/contents')
                                        }).catch(err => {
                                            console.log('errorr')
                                        })
                                    }
                                    }>Delete</ConfirmButton>
                                    <Button href={'/contents'} variant={'outline-danger'}>Cancel</Button>
                                    <Button type={"submit"} disabled={loading > 0}>Save</Button>

                                </Card.Footer>
                            </form>
                        </Card>
                    </Col>
                </Row>
            }
        </>
    );
};