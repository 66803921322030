import React from 'react';
import { useState } from 'react';
import ReactQuill, {ReactQuillProps} from 'react-quill';
import {Button} from "react-bootstrap";

interface Props extends ReactQuillProps{
}

const CustomEditor: React.FC<Props> = (props) => {
    const [raw, setRaw] = useState(false);
    // @ts-ignore
    return (
        <div>
            <div className={'text-editor'} style={{display: "flex", gap: 10, flexDirection: "column"}}>

                { !raw ?
                <ReactQuill {...props}/> :
                    <textarea
                        value={props.value as string}
                        onChange={(el => {
                        if (props?.onChange) {
                            // @ts-ignore
                            props?.onChange(el?.target?.value || '')
                        }
                    })} style={{width: '100%', minHeight: 300}} /> }
                <Button className={'btn-sm'} onClick={() => setRaw(!raw)}>{raw ? 'Preview' : 'Raw Html'}</Button>
            </div>
        </div>
    );
};

export default CustomEditor;