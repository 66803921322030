import PageTitle from "../../components/PageTitle";
import {Button, Card, Col, Row} from "react-bootstrap";
import Table from "../../components/Table";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../redux/store";
import {useNavigate} from "react-router-dom";
import {getActionTypes} from "../../redux/contents-slice";
import {ConfirmButton} from "../../components/ConfirmButton";
import config from "../../config";
import {APICore, serviceEndpoints} from "../../helpers/api/apiCore";

export const ActionTypeList = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();

    const columns = [
        {
            Header: "Name",
            accessor: "name",
            sort: true,
            search: true
        },
        {
            Header: "Action",
            accessor: 'idddd',
            sort: false,
            Cell: (item: any) => {

                return <div style={{display: 'flex', gap: 10}}><Button size={'sm'} onClick={() => {
                    return navigate(`/contents/action-types/${item?.row?.original?.id}`, {
                        state: {
                            actionType: item?.row?.original
                        }
                    })
                }}>Edit</Button>
                    <ConfirmButton
                        variant={'danger'}
                        onOk={() => {
                            const contentUrl = config.CONTENT_API_URL;
                            const apiCore = new APICore();
                            return apiCore.delete(contentUrl + serviceEndpoints.actionTypeDelete.replace(':id', item?.row?.original?.id)).then(() => {
                                dispatch(getActionTypes())
                            }).catch(err => {
                                console.log('error')
                            })
                        }}
                        size={'sm'}>Delete</ConfirmButton>
                </div>
            }
        }
    ];


    useEffect(() => {
        dispatch(getActionTypes())
    }, [])

    const {data, loading, error} = useSelector(
        (state: RootState) => state.Content.actionTypes
    );

    console.log(data);
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "", path: "/contents"},
                ]}
                title={"Categories"}
            />

            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col className={'d-flex align-content-end justify-content-end'}><Button onClick={() => {
                                    navigate('/contents/action-types/create')
                                }}>Add New Action Type</Button></Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <h4 className="header-title">Action Type List</h4>
                            <p className="text-muted fs-14 mb-4">
                                A simple example of table with pagination and column sorting
                            </p>

                            <Table
                                isSearchable={true}
                                columns={columns}
                                data={data || []}
                                pageSize={20}
                                isSortable={true}
                                pagination={true}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};