import PageTitle from "../../components/PageTitle";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { getGiftCodeOptions } from "../../types/gift-code";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { APICore, serviceEndpoints } from "../../helpers/api/apiCore";
import { getEmailTemplates } from "../../redux/email-template-slice";
import { RootState } from "../../redux/store";
import Select from "react-select";
import { CampaignFormProps } from "../../types/campaign";
import { getCampaigns } from "../../redux/campaigns-slice";
import FileUploader from "../../components/FileUploader";


const apiCore = new APICore();

export const CreateCampaign = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEmailTemplates())
    }, [])

    const {data, loading: templatesLoading, error} = useSelector(
        (state: RootState) => state.EmailTemplate.templates
    );
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const {handleSubmit, control, getValues, formState: {errors}} = useForm<CampaignFormProps>({
        resolver: yupResolver(object().shape({
            name: string().required(),
            template_id: string().required()
        }))
    })


    const onSubmit = (formData: CampaignFormProps) => {
        setLoading(true)
        const data = new FormData();
        data.append('name', formData.name);
        data.append('email_template_id', formData.template_id);
        data.append('subscription_type', formData.subscription_type);
        data.append('file', formData.file);
        apiCore.create(serviceEndpoints.campaignList, data).then(() => {
            setLoading(false)
            dispatch(getCampaigns());
            navigate('/campaigns')
        }).catch(() => setLoading(false))
    }
    return (<>
            <PageTitle
                breadCrumbItems={[
                    {label: "Campaigns", path: "/campaigns"},
                    {
                        label: 'Campaign Create',
                        path: `/campaign/create`
                    },

                ]}
                title={"Campaign Create"}
            />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Name</Form.Label>
                                    <Controller control={control} name={'name'} render={({field}) => {
                                        return (
                                            <Form.Control
                                                placeholder="Name"
                                                type={'text'}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                        )
                                    }}/>
                                    <Form.Control.Feedback
                                        type={'invalid'}> {errors?.name?.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col></Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Subscription Days</Form.Label>
                                    <Controller control={control} name={'subscription_type'} render={({field}) => {
                                        return (
                                            <Select
                                                options={getGiftCodeOptions()}
                                                onChange={(it) => {
                                                    field.onChange(it?.value as string);
                                                }}
                                            />
                                        )
                                    }}/>
                                    <Form.Control.Feedback
                                        type={'invalid'}> {errors?.subscription_type?.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Template</Form.Label>
                                    <Controller control={control} name={'template_id'} render={({field}) => {
                                        return (
                                            <Select
                                                onChange={(it) => {
                                                    field.onChange(it?.value);
                                                }}
                                                options={data?.filter(it => it.type === 'GIFT_CODE')?.map(it => {
                                                    return {
                                                        value: it.id,
                                                        label: it.title
                                                    }
                                                })}
                                            />
                                        )
                                    }}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Email List</Form.Label>
                                    <Controller control={control} name={'file'} render={({field}) => {
                                        return <FileUploader onFileUpload={(file) => {
                                            field.onChange(file[0])
                                        }}/>
                                    }}/>
                                    <div style={{marginTop: 10, display: 'flex', justifyContent:'center'}}>
                                        <a onClick={() => {
                                            alert('Please dont change template')
                                        }} className={'btn btn-primary'} href={'/template.csv'}>Download CSV Template</a>
                                    </div>
                                </Form.Group>

                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <Button disabled={loading}
                                onClick={handleSubmit(onSubmit)}
                                variant={'primary'}>Save</Button>
                    </Card.Footer>
                </Card>
            </Form>

        </>
    );
};