import {APICore, getUserFromCookie, serviceEndpoints} from "../helpers/api/apiCore";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {LoginUserInfo} from "../pages/auth/Login";
import {TokenResponseDTO} from "../types/auth";

const INIT_STATE: AuthSliceProps = {
    user: getUserFromCookie(),
    loading: false,
};

const apiCore = new APICore();

type AuthSliceProps = {
    user?: UserDataProps | {},
    loading?: boolean,
    value?: boolean,
    error?: string,
    passwordReset?: boolean,
    resetPasswordSuccess?: {
        message: string
    },
    userLoggedIn?: boolean,
    userSignUp?: boolean

}
type UserDataProps = {
    id: number,
    email: string,
    name_surname: string
}

type AuthPayload = TokenResponseDTO & UserDataProps

const login = createAsyncThunk<AuthPayload, LoginUserInfo>(
    'auth/login',
    async (data, thunkAPI) => {
        const loginResponse = await apiCore.create(serviceEndpoints.login, data )
            .then(it => it.data);
        await apiCore.setLoggedInUser(loginResponse);
        const profileResponse = await apiCore.get(serviceEndpoints.profile).then(it => it.data);
        return {
            ...loginResponse,
            ...profileResponse
        }
    }
)

const refreshLogin = createAsyncThunk<AuthPayload, string>(
    'auth/refresh',
    async (refreshToken, thunkAPI) => {
        const loginResponse = await apiCore.get(serviceEndpoints.refreshLogin, {
            refresh_token: refreshToken
        } )
            .then(it => it.data);
        await apiCore.setLoggedInUser(loginResponse);
        const profileResponse = await apiCore.get(serviceEndpoints.profile).then(it => it.data);
        return {
            ...loginResponse,
            ...profileResponse
        }
    }
)


const authSlice = createSlice({
    name: 'authSlice',
    initialState: INIT_STATE,
    reducers: {
        logoutUser: state => {
            apiCore.setLoggedInUser(null);
            state.user = undefined;
        }
    },
    extraReducers: builder => {
        builder.addCase(login.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        })
        builder.addCase(login.fulfilled, (state, {payload}) => {
            state.loading = false
            state.user = payload;
        })
        builder.addCase(login.rejected, (state, {error}) => {
            state.error = error.message
            state.loading = false
        })
        builder.addCase(refreshLogin.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        })
        builder.addCase(refreshLogin.fulfilled, (state, {payload}) => {
            state.loading = false
            state.user = payload;
        })
        builder.addCase(refreshLogin.rejected, (state, {error}) => {
            state.error = error.message
            state.loading = false
        })
    }
})

export default authSlice.reducer;

export {
    login,
    refreshLogin
}
export const {
    logoutUser
} = authSlice.actions