import {APICore, PaginationResponse, serviceEndpoints} from "../helpers/api/apiCore";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {BaseSliceState} from "./base-slice-state";
import {SportItem, SportItemFormProps} from "../types/SportItem";

export type SportSliceProps = {
    sports: BaseSliceState<SportItem[]>,
    sportDetail: BaseSliceState<SportItem>,
    creating: boolean,
    updating: boolean
}
const INIT_STATE: SportSliceProps = {
    sports: {
        data: []
    },
    sportDetail: {
        data: undefined
    },
    creating: false,
    updating: false
};

const apiCore = new APICore();

const getSports = createAsyncThunk<PaginationResponse<SportItem>>(
    'sports/list',
    async (_, thunkAPI) => {
        return apiCore.get(serviceEndpoints.sportList).then(it => it.data)
    }
)

const updateSport = createAsyncThunk<void, {
    id: string,
    formData: SportItemFormProps
}>(
    'sports/update',
    async (props) => {
        return apiCore.updatePatch(serviceEndpoints.sportUpdate.replace(':id', props.id), props.formData).then(it => it.data)
    }
)
const createSport = createAsyncThunk<void, SportItemFormProps>(
    'sports/create',
    async (props) => {
        return apiCore.create(serviceEndpoints.sportCreate, props).then(it => it.data)
    }
)


const sportsSlice = createSlice({
    name: 'authSlice',
    initialState: INIT_STATE,
    reducers: {
        resetSports: state => {
            state.sports.data = []
        }
    },
    extraReducers: builder => {
        builder.addCase(getSports.pending, (state) => {
            state.sports.loading = true;
            state.sports.error = undefined;
        })
        builder.addCase(getSports.fulfilled, (state, {payload}) => {
            state.sports.loading = false
            state.sports.data = payload.data;
        })
        builder.addCase(getSports.rejected, (state, {error}) => {
            state.sports.error = error.message
            state.sports.loading = false
        })
        builder.addCase(updateSport.pending, (state) => {
            state.sports.loading = true;
            state.updating = true;
        })
        builder.addCase(updateSport.fulfilled, (state, {payload}) => {
            state.updating = false;
        })
        builder.addCase(updateSport.rejected, (state, {error}) => {
            state.updating = false;
        })
    }
})

export default sportsSlice.reducer;


export {
    getSports,
    updateSport,
    createSport
}
export const {
    resetSports,
} = sportsSlice.actions