import PageTitle from "../../components/PageTitle";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import FormInput from "../../components/FormInput";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {object, string} from "yup";
import {SportItemFormProps} from "../../types/SportItem";
import FileUploader from "../../components/FileUploader";
import {APICore} from "../../helpers/api/apiCore";
import {createSport} from "../../redux/sports-slice";


export const SportCreate = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>()
    const history = useLocation();
    const navigate = useNavigate()
    const [loading, setLoading] = useState<number>(0)


    const {control, register, setValue, handleSubmit, formState: {errors}} = useForm<SportItemFormProps>({
        defaultValues: {},
        resolver: yupResolver(object().shape({
            title: string().label('Title').required(),
            subtitle: string().label('Subtitle').required(),
            promoted_title: string().label('Promoted Title').required(),
            promoted_subtitle: string().label('Promoted SubTitle').required(),
            selected_image: string().label('Selected Image').required(),
            image: string().label('Image').required()
        }))
    })
    const onSubmit = (data: SportItemFormProps) => {
        dispatch(createSport(data)).unwrap().then(() => {
            navigate('/sports')
        }).catch(() => {

        })
    }
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "Sports", path: "/sports"},
                    {label: history?.state ? history?.state?.sport?.title : 'Create Sport', path: `/sports/create`},

                ]}
                title={"Create Sport"}
            />
            <Row>
                <Col>
                    <Card>
                        <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <Card.Body>
                                <h4 className="header-title">Create Sport</h4>
                                <p className="text-muted fs-14 mb-4"></p>

                                <Row>

                                    <Col>
                                        <FormInput
                                            errors={errors}
                                            register={register}
                                            control={control}
                                            label={"Title"}
                                            name="title"
                                            placeholder="Enter Sport Title"
                                            containerClass={"mb-3"}
                                        />

                                        <FormInput
                                            errors={errors}
                                            register={register}
                                            control={control}
                                            label={"Sub Title"}
                                            name="subtitle"
                                            placeholder="Enter Sport Sub Title"
                                            containerClass={"mb-3"}
                                        />

                                        <FormInput
                                            type={'checkbox'}
                                            errors={errors}
                                            register={register}
                                            control={control}
                                            label={"Is Active"}
                                            name="is_active"
                                            placeholder="Is Active"
                                            containerClass={"mb-3"}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormInput
                                            errors={errors}
                                            register={register}
                                            control={control}
                                            label={"Promoted Title"}
                                            name="promoted_title"
                                            placeholder="Promoted Title"
                                            containerClass={"mb-3"}
                                        />

                                        <FormInput
                                            errors={errors}
                                            register={register}
                                            control={control}
                                            label={"Promoted Sub Title"}
                                            name="promoted_subtitle"
                                            placeholder="Enter Sport Promoted SubTitle"
                                            containerClass={"mb-3"}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="aaa">
                                            <Form.Label>Image</Form.Label>
                                            <FileUploader
                                                onFileUpload={(files) => {
                                                    if (files.length > 0) {
                                                        const apiCore = new APICore();
                                                        setLoading(loading + 1)
                                                        apiCore.createWithFile('/sports/media', {
                                                            file: files[0]
                                                        }).then((data) => {
                                                            setLoading(loading - 1)
                                                            setValue('image', data.data.path)
                                                        })
                                                    }
                                                }}
                                            />

                                            {errors?.image?.message}
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="bbb">
                                            <Form.Label>Selected Image</Form.Label>
                                            <FileUploader
                                                onFileUpload={(files) => {
                                                    if (files.length > 0) {
                                                        setLoading(loading + 1)
                                                        const apiCore = new APICore();
                                                        apiCore.createWithFile('/sports/media', {
                                                            file: files[0]
                                                        }).then((data) => {
                                                            setLoading(loading - 1)
                                                            setValue('selected_image', data.data.path)
                                                        })
                                                    }
                                                }}
                                            />

                                            {errors?.selected_image?.message}
                                        </Form.Group>
                                    </Col>
                                </Row>

                            </Card.Body>
                            <Card.Footer style={{display: 'flex', justifyContent: 'flex-end', gap: 20}}>
                                <Button href={'/sports'} variant={'outline-danger'}>Cancel</Button>
                                <Button type={"submit"} disabled={loading > 0}>Save</Button>
                            </Card.Footer>
                        </form>
                    </Card>
                </Col>
            </Row>

        </>
    );
};