import {createSlice} from "@reduxjs/toolkit";
import {LayoutTypes, LayoutWidth, MenuPositions, SideBarTheme, SideBarTypes, TopbarTheme} from "../constants";
type layoutSliceProps = {
    layoutType: LayoutTypes,
    layoutWidth: LayoutWidth,
    menuPosition: MenuPositions,
    leftSideBarTheme: SideBarTheme,
    leftSideBarType: SideBarTypes,
    showSidebarUserInfo: boolean,
    topbarTheme: TopbarTheme,
    isOpenRightSideBar: boolean,
    showTwoToneIcons?: boolean,
    showRightSidebar: boolean
}
const initialState: layoutSliceProps = {
    layoutType: LayoutTypes.LAYOUT_VERTICAL,
    layoutWidth: LayoutWidth.LAYOUT_WIDTH_FLUID,
    menuPosition: MenuPositions.MENU_POSITION_FIXED,
    leftSideBarTheme: SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT,
    leftSideBarType: SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT,
    showSidebarUserInfo: false,
    topbarTheme: TopbarTheme.TOPBAR_THEME_LIGHT,
    isOpenRightSideBar: false,
    showRightSidebar: false
}
const layoutSlice = createSlice({
    name: 'layoutSlice',
    initialState,
    reducers: {}
})

export type {
    layoutSliceProps
}
export default layoutSlice.reducer;