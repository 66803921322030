import PageTitle from "../../components/PageTitle";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {Navigate, useLocation, useNavigate, useParams} from "react-router-dom";
import FormInput from "../../components/FormInput";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {object, string} from "yup";
import {SportItemFormProps} from "../../types/SportItem";
import FileUploader from "../../components/FileUploader";
import {APICore} from "../../helpers/api/apiCore";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;
import {updateSport} from "../../redux/sports-slice";
import {ConfirmButton} from "../../components/ConfirmButton";


export const SportDetail = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>()
    const history = useLocation();
    const params = useParams();
    const navigate = useNavigate()
    const data = history.state.sport;
    const [loading, setLoading] = useState<number>(0)


    const {control, register, watch, setValue, handleSubmit, reset, formState: {errors, dirtyFields}} = useForm<SportItemFormProps>({
        defaultValues: {
            ...data,
        },
        resolver: yupResolver(object().shape({
            title: string().label('Title').required(),
            subtitle: string().label('Subtitle').required(),
            promoted_title: string().label('Promoted Title').required(),
            promoted_subtitle: string().label('Promoted SubTitle').required(),
            selected_image:string().label('Selected Image').required(),
            image:string().label('Image').required()
        }))
    })
    if (!data) {
        return <Navigate to={'/sports'} />
    }

    const onSubmit = (data: SportItemFormProps) => {
        dispatch(updateSport({
            id: params.id as string,
            formData: data
        })).unwrap().then(() => {
            navigate('/sports')
        }).catch(() => {

        })
    }
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "Sports", path: "/sports"},
                    {label: history?.state ? history?.state?.sport?.title : 'Sport Detail', path: `/sports/${params.id}`},

                ]}
                title={"Sport Detail"}
            />
            {data &&

                <Row>
                    <Col>
                        <Card>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <Card.Body>
                                <h4 className="header-title">Edit Sport Details</h4>
                                <p className="text-muted fs-14 mb-4"></p>

                                    <Row>

                                        <Col>
                                            <FormInput
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Title"}
                                                name="title"
                                                placeholder="Enter Sport Title"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                        <Col>
                                            <FormInput
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Sub Title"}
                                                name="subtitle"
                                                placeholder="Enter Sport Sub Title"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                        <Col>
                                            <FormInput
                                                type={'checkbox'}
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Is Active"}
                                                name="is_active"
                                                placeholder="Is Active"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormInput
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Promoted Title"}
                                                name="promoted_title"
                                                placeholder="Promoted Title"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                        <Col>
                                            <FormInput
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Promoted Sub Title"}
                                                name="promoted_subtitle"
                                                placeholder="Enter Sport Promoted SubTitle"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                    </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="validationCustom03">
                                            <Form.Label>Image</Form.Label>
                                        <FileUploader
                                            onFileUpload={(files) => {
                                                if (files.length > 0) {
                                                    const apiCore = new APICore();
                                                    setLoading(loading + 1)
                                                    apiCore.createWithFile('/sports/media', {
                                                        file: files[0]
                                                    }).then((data) => {
                                                        setLoading(loading - 1)
                                                        setValue('image', data.data.path)
                                                    })
                                                }
                                            }}
                                        />
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.image?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="validationCustom03">
                                            <Form.Label>Selected Image</Form.Label>
                                            <FileUploader
                                                onFileUpload={(files) => {
                                                    if (files.length > 0) {
                                                        setLoading(loading + 1)
                                                        const apiCore = new APICore();
                                                        apiCore.createWithFile('/sports/media', {
                                                            file: files[0]
                                                        }).then((data) => {
                                                            setLoading(loading - 1)
                                                            setValue('selected_image', data.data.path)
                                                        })
                                                    }
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.selected_image?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                            </Card.Body>
                            <Card.Footer style={{display: 'flex',justifyContent: 'flex-end', gap: 20}}>
                                <ConfirmButton variant={'danger'} disabled={loading > 0} onOk={() => {
                                    const apiCore = new APICore();
                                    return apiCore.delete(`/sports/${data?.id}`).then(() => {
                                        setLoading(0);
                                        navigate('/sports')
                                    }).catch(err => {
                                        console.log('error')
                                    })
                                }
                                }>Delete</ConfirmButton>
                                <Button variant={'outline-danger'} href={'/sports'}>Cancel</Button>
                                <Button type={"submit"} disabled={loading > 0}>Save</Button>

                            </Card.Footer>
                        </form>
                        </Card>
                    </Col>
                </Row>
            }
        </>
    );
};