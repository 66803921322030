import PageTitle from "../../components/PageTitle";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../redux/store";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import FormInput from "../../components/FormInput";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {object, string} from "yup";
import {ContentFormProps} from "../../types/Content";
import {createContent, getActionTypes, getCategories} from "../../redux/contents-slice";
import ReactQuill from "react-quill";
import CustomEditor from "../../components/CustomEditor";


export const ContentCreate = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>()
    const history = useLocation();
    const params = useParams();
    const navigate = useNavigate()
    const [loading, setLoading] = useState<number>(0)

    const {data: actionTypes = [], loading: actionTypesLoading } = useSelector(
        (state: RootState) => state.Content.actionTypes
    );
    const {data: categories = [], loading:categoriesLoading, error } = useSelector(
        (state: RootState) => state.Content.categories
    );

    const {control, register, watch, setValue, handleSubmit, reset, formState: {errors, dirtyFields}} = useForm<ContentFormProps>({
        defaultValues: {},
        resolver: yupResolver(object().shape({
            title: string().label('Title').required(),
            subtitle: string(),
            body: string(),
            action_type_id: string().label('Action Type').required(),
            category_id: string().label('Action Type').required(),
            slug: string()

        }))
    })

    console.log(errors)

    useEffect(() => {
        dispatch(getCategories())
        dispatch(getActionTypes())

    }, [])

    const onSubmit = (data: ContentFormProps) => {
        dispatch(createContent(data)).unwrap().then(() => {
            navigate('/contents')
        }).catch(() => {

        })
    }
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "Contents", path: "/contents"},
                    {label: 'Create Content', path: `/contents/create`},

                ]}
                title={"Content Create"}
            />

                <Row>
                    <Col>
                        <Card>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <Card.Body>
                                <h4 className="header-title">Create Content</h4>
                                <p className="text-muted fs-14 mb-4"></p>

                                    <Row>

                                        <Col>
                                            <FormInput
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Title"}
                                                name="title"
                                                placeholder="Enter Title"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                    </Row><Row>
                                            <FormInput
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Subtitle"}
                                                name="subtitle"
                                                placeholder="Sub Title"
                                                containerClass={"mb-3"}
                                            />

                                    </Row>
                                <Row>
                                    <Col>

                                        <Form.Group className="mb-3" controlId="validationCustom03">
                                            <Form.Label>Action Type</Form.Label>
                                            <Controller control={control}
                                                        name={'action_type_id'}
                                                        render={({field}) => {
                                                            return <Form.Select
                                                                isInvalid={errors?.action_type_id !== undefined}
                                                                onChange={field.onChange}
                                                                value={field.value}
                                                            >
                                                                <option>Choose</option>
                                                                {
                                                                    actionTypes.map(it => {
                                                                        return (
                                                                            <option value={it.id}>{it.name}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </Form.Select>
                                                        }} />

                                            <Form.Control.Feedback type="invalid">
                                                {errors?.action_type_id?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                        <Form.Group className="mb-3" controlId="validationCustom03">
                                            <Form.Label>Category</Form.Label>
                                            <Controller control={control}
                                                        name={'category_id'}
                                                        render={({field}) => {
                                                            return <Form.Select
                                                                isInvalid={errors?.category_id !== undefined}
                                                                onChange={field.onChange}
                                                                value={field.value}
                                                            >
                                                                <option>Choose</option>
                                                                {
                                                                    categories.map(it => {
                                                                        return (
                                                                            <option value={it.id}>{it.name}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </Form.Select>
                                                        }} />

                                            <Form.Control.Feedback type="invalid">
                                                {errors?.category_id?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                </Row>
                                <Row>
                                    <Col>
                                        <Controller
                                            control={control}
                                            name={'body'}
                                            render={({field}) => {
                                            return (
                                                <CustomEditor
                                                    value={field.value}
                                                    onChange={(e) => {field.onChange(e)}}
                                                    theme={'snow'} modules={
                                                    {
                                                        toolbar: [
                                                            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                                                            [{size: []}],
                                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                            [{'list': 'ordered'}, {'list': 'bullet'},
                                                                {'indent': '-1'}, {'indent': '+1'}],
                                                            ['link', 'image', 'video'],
                                                            ['clean']
                                                        ],
                                                        clipboard: {
                                                            // toggle to add extra line breaks when pasting HTML:
                                                            matchVisual: false,
                                                        }
                                                    }} />
                                            )
                                        } }/>

                                    </Col>
                                </Row>
                                <Row><Col>

                                    <FormInput
                                        errors={errors}
                                        register={register}
                                        control={control}
                                        label={"Slug"}
                                        name="slug"
                                        placeholder="Enter Slug"
                                        containerClass={"mb-3"}
                                    />

                                </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer style={{display: 'flex',justifyContent: 'flex-end', gap: 20}}>
                                <Button variant={'outline-danger'} href={'/contents'}>Cancel</Button>
                                <Button type={"submit"} disabled={loading > 0}>Save</Button>

                            </Card.Footer>
                        </form>
                        </Card>
                    </Col>
                </Row>
        </>
    );
};