import {Button, ButtonProps, Modal} from "react-bootstrap";
import React, {FC, useState} from "react";

type ConfirmWrapperProps = ButtonProps & {
    body?: React.ElementType | string,
    title?: string,
    onOk: () =>  Promise<void>
}
export const ConfirmButton: FC<ConfirmWrapperProps> = (
    {
        title = 'Are you sure?',
        body = 'Are you sure to want to continue ?',
        onOk,
        children,
        ...props
    }
    ) => {

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    return (
        <>
            <Button {...props} onClick={() => setOpen(true)}>{children}</Button>
            <Modal
                {...props}
                show={open}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading} variant="secondary" onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        variant="primary" onClick={() => {
                            setLoading(true)
                        onOk().then(() => {
                            setLoading(false);
                            setOpen(false)
                        }).catch(() => {
                            setLoading(false)
                        })
                    }}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}