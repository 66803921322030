import { APICore, serviceEndpoints } from "../helpers/api/apiCore";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseSliceState } from "./base-slice-state";
import { CampaignItem } from "../types/campaign";

export type CampaignSliceProps = {
    campaigns: BaseSliceState<CampaignItem[]>,

}
const INIT_STATE: CampaignSliceProps = {
    campaigns: {
        data: []
    },
};


const apiCore = new APICore();
const getCampaigns = createAsyncThunk<CampaignItem[]>(
    'campaign/list',
    async (_, thunkAPI) => {
        return apiCore.get( serviceEndpoints.campaignList).then(it => {
            console.log('data', it.data)
            return it.data
        })
    }
)

const campaignSlice = createSlice({
    name: 'campaingsSlice',
    initialState: INIT_STATE,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getCampaigns.pending, (state) => {
            state.campaigns.loading = true;
            state.campaigns.error = undefined;
        })
        builder.addCase(getCampaigns.fulfilled, (state, {payload}) => {
            state.campaigns.loading = false
            console.log('aaa', payload)
            state.campaigns.data = payload;
        })
        builder.addCase(getCampaigns.rejected, (state, {error}) => {
            state.campaigns.error = error.message
            state.campaigns.loading = false
        })

    }
})

export default campaignSlice.reducer;


export {
   getCampaigns
}
export const {
} = campaignSlice.actions