import PageTitle from "../../components/PageTitle";
import {Button, Card, Col, Row} from "react-bootstrap";
import Table from "../../components/Table";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../redux/store";
import {useNavigate} from "react-router-dom";
import {getSports} from "../../redux/sports-slice";

export const SportList = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();

    const columns = [
        {
            Header: "Title",
            accessor: "title",
            sort: true,
            search: true
        },
        {
            Header: "Action",
            accessor: 'idddd',
            sort: false,
            Cell: (item: any) => {

                return <Button size={'sm'} onClick={() => {
                    return navigate(`/sports/${item?.row?.original?.id}`, {
                        state: {
                            sport: item?.row?.original
                        }
                    })
                }} >Edit</Button>
            }
        }
    ];


    useEffect(() => {
        dispatch(getSports())
    }, [])

    const {data, loading, error } = useSelector(
        (state: RootState) => state.Sport.sports
    );
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "Sports", path: "/sports"},
                ]}
                title={"Sports"}
            />

            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col className={'d-flex align-content-end justify-content-end'}><Button onClick={() => {navigate('/sports/create')}}>Add New Sport</Button></Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <h4 className="header-title">Sport List</h4>
                            <p className="text-muted fs-14 mb-4">
                                A simple example of table with pagination and column sorting
                            </p>

                            <Table
                                isSearchable={true}
                                columns={columns}
                                data={data || []}
                                pageSize={20}
                                isSortable={true}
                                pagination={true}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};