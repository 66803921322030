import PageTitle from "../../components/PageTitle";
import {Button, Row} from "react-bootstrap";
import React, {FC, useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../redux/store";
import {useLocation, useNavigate} from "react-router-dom";
import {CategoryFormProps, CategoryItem} from "../../types/Content";
import {createCategory, getCategories, sortCategories} from "../../redux/contents-slice";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import update from 'immutability-helper'
import {Identifier, XYCoord} from 'dnd-core'
import {HTML5Backend} from "react-dnd-html5-backend";

type ContainerProps = {
    cards: Item[],
    onSort: (data: Item[]) => void
}
export const Container: FC<ContainerProps> = ({cards: cardCollection, onSort}) => {
    {
        const [cards, setCards] = useState<Item[]>(cardCollection)

        const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
            setCards((prevCards: Item[]) =>
                update(prevCards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevCards[dragIndex] as Item],
                    ],
                }),
            )
        }, [])

        useEffect(() => {
            onSort(cards)
        }, [cards])

        const renderCard = useCallback(
            (card: { id: string; text: string }, index: number) => {
                return (
                    <Card
                        key={card.id}
                        index={index}
                        id={card.id}
                        text={card.text}
                        moveCard={moveCard}
                    />
                )
            },
            [],
        )

        return (
            <>
                <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
            </>
        )
    }
}
export const CategorySort = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>()
    const history = useLocation();
    const navigate = useNavigate()

    const [categories, setCategories] = useState<Record<string, any>[]>();
    useEffect(() => {
        dispatch(getCategories())
    }, [])



    const {data, loading, error} = useSelector(
        (state: RootState) => state.Content.categories
    );

    useEffect(() => {
        setCategories(data);
    }, [data]);


    const onSubmit = (data: CategoryFormProps) => {
        dispatch(createCategory(data)).unwrap().then(() => {
            navigate('/contents/categories')
        }).catch(() => {

        })
    }
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "Contents", path: "/contents"},
                    {label: 'Create Category', path: `/contents/categories/create`},

                ]}
                title={"Sort Categories"}
            />
                <Row>
                    {data?.length ?
                    <DndProvider backend={HTML5Backend}>
                        <Container
                            cards={
                                (data?.map(it => {
                                return {
                                    id: it.id,
                                    text: it.name
                                }
                            }) || []) as Item[]}
                        onSort={(cards) =>{setCategories(cards)}}
                        />
                    </DndProvider> : ''
                    }
                    <Button onClick={() => {
                        dispatch(sortCategories(categories?.map(it => it.id) || []))?.unwrap().then(() => {
                            navigate('/contents/categories')
                        })
                    }}>Save</Button>
                </Row>

        </>
    );
};



export const ItemTypes = {
    CARD: 'card',
}

export interface Item {
    id: string
    text: string
}

export interface ContainerState {
    cards: Item[]
}




const style = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
}

export interface CardProps {
    id: any
    text: string
    index: number
    moveCard: (dragIndex: number, hoverIndex: number) => void
}

interface DragItem {
    index: number
    id: string
    type: string
}

export const Card: FC<CardProps> = ({ id, text, index, moveCard }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index }
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))
    return (
        <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
            {text}
        </div>
    )
}
