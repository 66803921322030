import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { GiftCodeCreateProps } from "../../types/subscription";
import { APICore } from "../../helpers/api/apiCore";
import { UserDetailItem } from "../../types/user";
const apiCore = new APICore();
type GiftCodeModalProps = {
    open: boolean,
    setOpen: (open: boolean) => void,
    user: UserDetailItem | undefined
}
export const GiftCodeModal: FC<GiftCodeModalProps> = ({open, setOpen, user}) => {
    const [loading, setLoading] = useState(false);

    const onSubmit = (data: GiftCodeCreateProps) => {
        setLoading(true);
        apiCore.create('subscription/gift-code/create', {
            days: Number(data.days),
            name_surname: user?.name_surname,
            email: user?.email
        }).then(() => {
            setLoading(false)
            setOpen(false)
        }).catch(err => {
            setLoading(false)
            alert(err.message);
        })
    }
    const {control, handleSubmit} = useForm<GiftCodeCreateProps>({
        defaultValues: {
            days: 365
        }
    })
    return <Modal show={open}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  onHide={() => {
                      if (!loading) {
                          setOpen(false)
                      }
                  }
                  }
    >

        <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Send Gift Code to User
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="validationCustom01">
                            <Form.Label>Days</Form.Label>
                            <Controller control={control} name={'days'} render={({field}) => {
                                return (
                                    <Form.Control
                                        placeholder="365"
                                        type={'number'}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}/>
                            <Form.Control.Feedback> Looks good!</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Button variant={'secondary'} disabled={loading} onClick={() => setOpen(false)}>Close</Button>
                <Button disabled={loading}
                        onClick={handleSubmit(onSubmit)}
                        variant={'primary'}>Save</Button>
            </Modal.Footer>
        </Form>

    </Modal>
}