import PageTitle from "../../components/PageTitle";
import {Button, Card, Col, Row} from "react-bootstrap";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {useLocation, useNavigate} from "react-router-dom";
import FormInput from "../../components/FormInput";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {object, string} from "yup";
import {ActionTypeFromProps} from "../../types/Content";
import {createActionType} from "../../redux/contents-slice";


export const ActionTypeCreate = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>()
    const history = useLocation();
    const navigate = useNavigate()
    const [loading, setLoading] = useState<number>(0)


    const {control, register, setValue, handleSubmit, formState: {errors}} = useForm<ActionTypeFromProps>({
        defaultValues: {
        },
        resolver: yupResolver(object().shape({
            name: string().label('Name').required(),

        }))
    })

    const onSubmit = (data: ActionTypeFromProps) => {
        dispatch(createActionType(data)).unwrap().then(() => {
            navigate('/contents/action-types')
        }).catch(() => {

        })
    }
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "Contents", path: "/contents"},
                    {label: history?.state ? history?.state?.actionType?.title : 'Create Action Type', path: `/contents/action-types/create`},

                ]}
                title={"Create Sport"}
            />
                <Row>
                    <Col>
                        <Card>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <Card.Body>
                                <h4 className="header-title">Create Action Type</h4>
                                <p className="text-muted fs-14 mb-4"></p>

                                    <Row>

                                        <Col>
                                            <FormInput
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Name"}
                                                name="name"
                                                placeholder="Enter Type Name"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                </Row>

                            </Card.Body>
                            <Card.Footer style={{display: 'flex',justifyContent: 'flex-end', gap: 20}}>
                                <Button variant={'outline-danger'}>Cancel</Button>
                                <Button type={"submit"} disabled={loading > 0}>Save</Button>

                            </Card.Footer>
                        </form>
                        </Card>
                    </Col>
                </Row>

        </>
    );
};