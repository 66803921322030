import {APICore, PaginationResponse, serviceEndpoints} from "../helpers/api/apiCore";
import {createAsyncThunk, createDraftSafeSelector, createSlice} from "@reduxjs/toolkit";
import {BasePaginatedSliceState, BaseSliceState} from "./base-slice-state";
import { AddSubscriptionInfoForm, UserDetailItem, UserEditFormProps, UserItem, UserSubscription } from "../types/user";
import {RootState} from "./store";

export type UserSliceProps = {
    users: BasePaginatedSliceState<UserItem[]>,
    userDetail: BaseSliceState<UserDetailItem>,
    subscriptionInfo: BaseSliceState<UserSubscription>,
    isEditingSubs?: boolean
}
const INIT_STATE: UserSliceProps = {
    users: {
        data: []
    },
    userDetail: {
        data: undefined
    },
    subscriptionInfo: {
        data: undefined
    },

};

const apiCore = new APICore();

const getUsers = createAsyncThunk<PaginationResponse<UserItem>>(
    'users/list',
    async (_, thunkAPI) => {
        return apiCore.get(serviceEndpoints.userList).then(it => it.data)
    }
)

const getUserById = createAsyncThunk<UserDetailItem, string>(
    'user/detail',
    async (userId, thunkAPI) => {
        return apiCore.get(serviceEndpoints.userDetail.replace(':id', userId))
            .then(it => it.data)
    }
)

const updateUser = createAsyncThunk<void, { id: string, formData: { [key: string]: any } }>(
    'user/update',
    async (data, thunkAPI) => {
        return apiCore.updatePatch(serviceEndpoints.userUpdate.replace(':id', data.id), data.formData)
            .then(it => it.data)
    }
)

const getSubscriptionInfo = createAsyncThunk<UserSubscription | undefined, string>(
    'user/subscriptionInfo',
    async (userId) => {
        return apiCore.get(serviceEndpoints.subscriptionInfo.replace(':userId', userId)).then(res => {
            return res.data
        })
    }
)

const addSubscriptionInfo = createAsyncThunk<void, AddSubscriptionInfoForm>(
    'user/addSubscription',
    async (formValues) => {
        console.log('girdik');
        return apiCore.create(serviceEndpoints.updateSubscription, formValues).then(res => {
            return;
        }).catch(err => console.log('err', err))
    }
)


const userSlice = createSlice({
    name: 'authSlice',
    initialState: INIT_STATE,
    reducers: {
        resetUsers: state => {
            state.users.data = []
        }
    },
    extraReducers: builder => {
        builder.addCase(getUsers.pending, (state) => {
            state.users.loading = true;
            state.users.error = undefined;
        })
        builder.addCase(getUsers.fulfilled, (state, {payload}) => {
            state.users.loading = false
            state.users = payload;
        })
        builder.addCase(getUsers.rejected, (state, {error}) => {
            state.users.error = error.message
            state.users.loading = false
        })
        builder.addCase(getUserById.pending, (state) => {
            state.userDetail.loading = true;
            state.userDetail.error = undefined;
            state.users.data = undefined;
        })
        builder.addCase(getUserById.fulfilled, (state, {payload}) => {
            state.userDetail.loading = false
            state.userDetail.data = payload;
        })
        builder.addCase(getUserById.rejected, (state, {error}) => {
            state.userDetail.error = error.message
            state.userDetail.loading = false
        })
        builder.addCase(getSubscriptionInfo.pending, (state) => {
            state.subscriptionInfo.loading = true;
        })
        builder.addCase(getSubscriptionInfo.fulfilled, (state, {payload}) => {
            state.subscriptionInfo.loading = false;
            state.subscriptionInfo.data = payload;
            state.subscriptionInfo.error = undefined;
        })
        builder.addCase(getSubscriptionInfo.rejected, (state, {error}) => {
            state.subscriptionInfo.loading = false;
            state.subscriptionInfo.data = undefined;
            state.subscriptionInfo.error = error?.message || '';
        })
        builder.addCase(addSubscriptionInfo.pending, (state) => {
            state.isEditingSubs = true
        })
        builder.addCase(addSubscriptionInfo.fulfilled, (state) => {
            state.isEditingSubs = false
        })
        builder.addCase(addSubscriptionInfo.rejected, (state) => {
            state.isEditingSubs = false
        })
    }
})

export default userSlice.reducer;

const getSubscriptionSelector = createDraftSafeSelector((state: RootState) => state,
    (state) => state.User.subscriptionInfo)

export {
    getUsers,
    getUserById,
    updateUser,
    getSubscriptionInfo,
    getSubscriptionSelector,
    addSubscriptionInfo
}
export const {
    resetUsers,
} = userSlice.actions