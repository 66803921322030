import PageTitle from "../../components/PageTitle";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { getSubscriptionInfo, getSubscriptionSelector, getUserById, updateUser } from "../../redux/user-slice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Gender, UserEditFormProps, UserType } from "../../types/user";
import FormInput from "../../components/FormInput";
import ShreyuDatepicker from "../../components/Datepicker";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { mixed, object, string } from "yup";
import dayjs from "dayjs";
import { getSports } from "../../redux/sports-slice";
import { SubscriptionInfo } from "./subscription-info";
import Select from "react-select";
import { getDirtyValues } from "../../utils";


export const UserDetail = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>()
    const history = useLocation();
    const params = useParams();
    const navigate = useNavigate()


    useEffect(() => {
        dispatch(getUserById(params.id as string))
        dispatch(getSports());
        dispatch(getSubscriptionInfo(params.id as string))
    }, [])

    const {data, loading, error} = useSelector(
        (state: RootState) => state.User.userDetail
    );

    const {data: subsInfo, loading: subsLoading, error: subsError} = useSelector(getSubscriptionSelector);


    const {data: sportData, loading: sportsLoading,} = useSelector(
        (state: RootState) => state.Sport.sports
    );

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: {errors, dirtyFields}
    } = useForm<UserEditFormProps>({
        defaultValues: {
            ...data,
            sport_type_id: data?.sport_type?.id
        },
        resolver: yupResolver(object().shape({
            password: string().min(8).nullable().default(null),
            name_surname: string().label('Name Surname').required(),
            email: string().email().required().label('Email'),
            gender: mixed().oneOf(Object.values(Gender)),
            user_type: mixed().oneOf(Object.values(UserType))
        }))
    })
    useEffect(() => {
        if (data) {
            reset({
                ...data,
                sport_type_id: data?.sport_type?.id,
                password: null
            })
        }
    }, [data])

    const onSubmit = (data: UserEditFormProps) => {
        const allData = getDirtyValues(dirtyFields, data);
        dispatch(updateUser({
            id: params.id as string,
            formData: allData
        })).unwrap().then(e => {
            navigate("/users");
        })
    }
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "Users", path: "/users"},
                    {label: history?.state ? history?.state?.user?.email : 'User Detail', path: `/users/${params.id}`},

                ]}
                title={"User Detail"}
            />
            {data &&
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Row>
                        <Col>
                            <Card>

                                <Card.Body>
                                    <h4 className="header-title">Edit User Details</h4>
                                    <p className="text-muted fs-14 mb-4"></p>

                                    <Row>
                                        <Col>
                                            <FormInput
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Name Surname"}
                                                name="name_surname"
                                                placeholder="Enter Name Surname"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                        <Col>
                                            <FormInput
                                                errors={errors}
                                                register={register}
                                                control={control}
                                                label={"Email"}
                                                type="email"
                                                name="email"
                                                placeholder="Enter your name"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                        <Col>
                                            <Controller control={control} render={({field}) => {
                                                return (
                                                    <>
                                                        <label style={{marginBottom: '0.5rem'}}>User Type</label>
                                                        <Select
                                                            value={{
                                                                label: field.value as string,
                                                                value: field.value as string
                                                            }}
                                                            onChange={(item) => {
                                                                field.onChange(item?.value)
                                                            }}
                                                            options={Object.keys(UserType).map((it) => {
                                                                return {
                                                                    label: it,
                                                                    value: it
                                                                }
                                                            })}
                                                        />
                                                    </>
                                                )
                                            }} name={'user_type'}/>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormInput
                                                errors={errors}
                                                label={"Password"}
                                                register={register}
                                                control={control}
                                                type="password"
                                                name="password"
                                                placeholder="Enter Password"
                                                containerClass={"mb-3"}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="validationCustom03">
                                                <Form.Label>Gender</Form.Label>
                                                <Controller control={control}
                                                            name={'gender'}
                                                            render={({field}) => {
                                                                return <Form.Select
                                                                    isInvalid={errors?.gender !== undefined}
                                                                    onChange={field.onChange}
                                                                    value={field.value}
                                                                >
                                                                    <option value={'MALE'}>MALE</option>
                                                                    <option value={'FEMALE'}>FEMALE</option>
                                                                    <option value={'NONE'}>NONE</option>

                                                                </Form.Select>
                                                            }}/>

                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.gender?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="validationCustom03">
                                                <Form.Label>Birth Date</Form.Label>
                                                <Controller control={control}
                                                            name={'birth_date'}
                                                            render={({field}) => {
                                                                return <ShreyuDatepicker
                                                                    hideAddon={true}
                                                                    dateFormat="yyyy-MM-dd"
                                                                    value={field?.value ? dayjs(field.value)?.toDate() : undefined}
                                                                    onChange={(date) => {
                                                                        field.onChange(dayjs(new Date(date)).format('YYYY-MM-DD'))
                                                                    }}
                                                                />
                                                            }}/>

                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.birth_date?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="validationCustom03">
                                                <Form.Label>Sport Type</Form.Label>
                                                <Controller control={control}
                                                            name={'sport_type_id'}
                                                            render={({field}) => {
                                                                return <Form.Select
                                                                    isInvalid={errors?.sport_type_id !== undefined}
                                                                    onChange={field.onChange}
                                                                    value={field.value}
                                                                >
                                                                    {
                                                                        sportData?.map(it => {
                                                                            return <option
                                                                                value={it.id}>{it.title}</option>
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            }}/>

                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.sport_type_id?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <SubscriptionInfo user={data} subscriptionInfo={subsInfo}
                                                      userId={params.id as string}/>

                                </Card.Body>
                                <Card.Footer
                                    style={{display: 'flex', justifyContent: 'flex-end', gap: 20}}>
                                    <Button variant={'outline-danger'}>Cancel</Button>
                                    <Button type={"submit"}>Save</Button>

                                </Card.Footer>

                            </Card>
                        </Col>
                    </Row>
                </form>
            }
        </>
    );
};