import PageTitle from "../../components/PageTitle";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getGiftCodes } from "../../redux/gift-code-slice";
import { Controller, useForm } from "react-hook-form";
import { getGiftCodeOptions, GiftCodeFormProps } from "../../types/gift-code";
import { yupResolver } from "@hookform/resolvers/yup";
import { number, object, string } from "yup";
import { APICore, serviceEndpoints } from "../../helpers/api/apiCore";
import { getEmailTemplates } from "../../redux/email-template-slice";
import { RootState } from "../../redux/store";
import Select from "react-select";

const apiCore = new APICore();

export const CreateGiftCode = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEmailTemplates())
    }, [])

    const {data, loading: templatesLoading, error} = useSelector(
        (state: RootState) => state.EmailTemplate.templates
    );
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const {handleSubmit, control, getValues, formState: {errors}} = useForm<GiftCodeFormProps>({
        resolver: yupResolver(object().shape({
            duration: string().required(),
            email: string().email(),
            template_id: string().required()
        }))
    })



    const onSubmit = (formData: GiftCodeFormProps) => {
        setLoading(true)
        apiCore.create(serviceEndpoints.giftCodeList, {
            ...formData
        }).then(() => {
            setLoading(false)
            dispatch(getGiftCodes());
            navigate('/gift-codes')
        }).catch(() => setLoading(false))
    }
    return (<>
            <PageTitle
                breadCrumbItems={[
                    {label: "Email templates", path: "/email-templates"},
                    {
                        label: 'Email Template Create',
                        path: `/email-templates/Create`
                    },

                ]}
                title={"Email Template Detail"}
            />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Subscription Days</Form.Label>
                                    <Controller control={control} name={'duration'} render={({field}) => {
                                        return (
                                            <Select
                                                options={getGiftCodeOptions()}
                                                onChange={(it) => {
                                                    field.onChange(it?.value as string);
                                                }}
                                            />
                                        )
                                    }}/>
                                    <Form.Control.Feedback type={'invalid'}> {errors?.duration?.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Template</Form.Label>
                                    <Controller control={control} name={'template_id'} render={({field}) => {
                                        return (
                                            <Select
                                                onChange={(it) => {
                                                    field.onChange(it?.value);
                                                }}
                                                options={data?.filter(it => it.type === 'GIFT_CODE')?.map(it => {
                                                    return {
                                                        value: it.id,
                                                        label: it.title
                                                    }
                                                })}
                                                />
                                        )
                                    }}/>
                                </Form.Group>
                            </Col>
                        </Row>


                        <Row style={{marginTop: 30}}>
                            <Col>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Email</Form.Label>
                                    <Controller control={control} name={'email'} render={({field}) => {
                                        return (
                                            <Form.Control
                                                placeholder="test@lvsports.app"
                                                type={'email'}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                        )
                                    }}/>
                                    <Form.Control.Feedback> {errors?.email?.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Name Surname (Optional)</Form.Label>
                                    <Controller control={control} name={'name_surname'} render={({field}) => {
                                        return (
                                            <Form.Control
                                                placeholder="Example"
                                                type={'text'}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                        )
                                    }}/>
                                    <Form.Control.Feedback> {errors?.email?.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>


                    </Card.Body>
                    <Card.Footer style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <Button disabled={loading}
                                onClick={handleSubmit(onSubmit)}
                                variant={'primary'}>Save</Button>
                    </Card.Footer>
                </Card>
            </Form>

        </>
    );
};