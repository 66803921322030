import React, { FC, useEffect, useState } from "react";
import { AddSubscriptionInfoForm, UserDetailItem, UserSubscription } from "../../types/user";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import ShreyuDatepicker from "../../components/Datepicker";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { addSubscriptionInfo, getSubscriptionInfo } from "../../redux/user-slice";
import { AppDispatch } from "../../redux/store";
import Datepicker from "../../components/Datepicker";
import { GiftCodeModal } from "./gift-code-modal";

type SubscriptionInfoProps = {
    subscriptionInfo?: UserSubscription,
    userId: string,
    user?: UserDetailItem
}
export const SubscriptionInfo: FC<SubscriptionInfoProps> = ({subscriptionInfo, userId, user}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [giftCodeOpen, setGiftCodeOpen] = useState(false);
    const {handleSubmit, control, reset, watch} = useForm<AddSubscriptionInfoForm>({
        defaultValues: {
            user_id: userId,
            start_at: subscriptionInfo?.started_at?.toString() || dayjs().format('YYYY-MM-DD'),
            expires_at: subscriptionInfo?.expires_at?.toString() || dayjs().format('YYYY-MM-DD'),
            type: subscriptionInfo?.type
        },
    });

    useEffect(() => {
        reset({
            user_id: userId,
            start_at: subscriptionInfo?.started_at?.toString() || dayjs().format('YYYY-MM-DD'),
            expires_at: subscriptionInfo?.expires_at?.toString() || dayjs().format('YYYY-MM-DD'),
            type: subscriptionInfo?.type
        })
    }, [subscriptionInfo]);

    const dispatch: AppDispatch = useDispatch<AppDispatch>();

    const onSubmit = (data: AddSubscriptionInfoForm) => {
        setLoading(true)
        dispatch(addSubscriptionInfo({
            start_at: dayjs(data.start_at).format('YYYY-MM-DD'),
            expires_at: dayjs(data.expires_at).format('YYYY-MM-DD'),
            type: 'WEB',
            user_id: userId
        })).then(() => {
            setLoading(false);
            dispatch(getSubscriptionInfo(userId));
            setOpen(false)
        }).catch(() => setLoading(false));
    }
    return (
        <Row>
            <Col>
                <h4 className="header-title">Subscription Info</h4>
                <Card>

                    <Card.Body>
                        <Row><Col>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 10}}>
                                <Button onClick={() => setOpen(true)}>Edit Subscription Details</Button>
                                <Button onClick={() => setGiftCodeOpen(true)}>Send Gift Code</Button>
                            </div>
                        </Col></Row>
                        <div className="text-muted mt-3">
                            {!subscriptionInfo ? 'No Subs' :
                                <Row>
                                    <Col lg={3} md={6}>
                                        <div className="mt-4">
                                            <p className="mb-2 text-uppercase fs-13 fw-bold">
                                                <i className="uil-calender text-danger"></i> Start Date
                                            </p>
                                            <h5 className="fs-16">{dayjs(subscriptionInfo.started_at).format('YYYY-MM-DD')}</h5>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="mt-4">
                                            <p className="mb-2 text-uppercase fs-13 fw-bold">
                                                <i className="uil-calendar-slash text-danger"></i> Due Date
                                            </p>
                                            <h5 className="fs-16">{dayjs(subscriptionInfo.expires_at).format('YYYY-MM-DD')}</h5>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="mt-4">
                                            <p className="mb-2 text-uppercase fs-13 fw-bold">
                                                <i className="uil-calendar-slash text-danger"></i> Subscription Type
                                            </p>
                                            <h5 className="fs-16">{subscriptionInfo.type}</h5>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </Card.Body>

                </Card>
            </Col>
            <GiftCodeModal user={user} open={giftCodeOpen} setOpen={setGiftCodeOpen} />
            <Modal show={open}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
                   onHide={() => {
                       if (!loading) {
                           setOpen(false)
                       }
                   }
            }
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Subscription Detail
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="validationCustom01">
                                    <Form.Label>Start At</Form.Label>
                                    <Controller control={control} name={'start_at'} render={({field}) => {
                                        return (
                                            <Datepicker
                                                dateFormat={'yyyy-MM-dd'}
                                                hideAddon={true}
                                                value={dayjs(field.value).toDate()}
                                                onChange={field.onChange}
                                            />
                                        )
                                    }}/>
                                    <Form.Control.Feedback> Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="validationCustom01">
                                    <Form.Label>Expires At</Form.Label>
                                    <Controller control={control} name={'expires_at'} render={({field}) => {
                                        return (
                                            <Datepicker
                                                dateFormat={'yyyy-MM-dd'}
                                                hideAddon={true}
                                                value={dayjs(field.value).toDate()}
                                                onChange={field.onChange}
                                            />
                                        )
                                    }}/>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Label>Type</Form.Label>
                                <Controller control={control} name={'type'} render={({field}) => {
                                    // @ts-ignore
                                    return (
                                        <Select
                                            onChange={field.onChange}
                                            value={field.value}
                                            placeholder="Choose a type..."
                                        />
                                    )
                                }}/>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'secondary'} disabled={loading} onClick={() => setOpen(false)}>Close</Button>
                        <Button disabled={loading}
                                onClick={handleSubmit(onSubmit)}
                                variant={'primary'}>Save</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Row>
    )
}