type EmailTemplateItem = {
    id: string,
    title: string,
    is_default: boolean,
    subject: string,
    content: string,
    type: string,
    variables: string,
    updated_at: string,
    created_at: string
}

type EmailTemplateForm = {
    id?: string,
    title: string,
    is_default?: boolean,
    subject: string,
    content: string,
    type: string
}

const  EmailTemplateTypes = {
    WELCOME:'WELCOME',
    GIFT_CODE : 'GIFT_CODE'
}

const EmailTemplateVariables = {
    [EmailTemplateTypes.WELCOME]: ['nameSurname', 'email'],
    [EmailTemplateTypes.GIFT_CODE]: ['*nameSurname', 'duration', 'giftCode', 'email']
}
export type {
    EmailTemplateItem,
    EmailTemplateForm
}

export {
    EmailTemplateTypes,
    EmailTemplateVariables
}