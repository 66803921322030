import PageTitle from "../../components/PageTitle";
import {Button, Card, Col, Row} from "react-bootstrap";
import Table from "../../components/Table";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../redux/store";
import {useNavigate} from "react-router-dom";
import {getSports} from "../../redux/sports-slice";
import {getCategories, getContents} from "../../redux/contents-slice";
import dayjs from "dayjs";
import {ConfirmButton} from "../../components/ConfirmButton";
import config from "../../config";
import {APICore, serviceEndpoints} from "../../helpers/api/apiCore";

export const ContentList = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();

    const columns = [
        {
            Header: "Title",
            accessor: "title",
            sort: true,
            search: true
        },
        {
            Header: "Subtitle",
            accessor: "subtitle",
            sort: true,
            search: true
        },
        {
            Header: "Category",
            accessor: "categoryaaa",
            Cell: (item: any) => {
                const it = item?.row?.original
                return it?.category?.name
            }
        },
        {
            Header: "Action Type",
            accessor: "action_type",
            Cell: (item: any) => {
                const it = item?.row?.original
                return it?.action_type?.name
            }
        },
        {
            Header: "Updated At",
            accessor: "updated_at",
            Cell: (item: any) => {
                return dayjs(item?.updated_at).format('YYYY-MM-DD H:m:s')
            }
        },
        {
            Header: "Action",
            accessor: 'idddd',
            sort: false,
            Cell: (item: any) => {

                return <div style={{display: 'flex', gap: 10}}>
                    <Button size={'sm'} onClick={() => {
                        return navigate(`/contents/${item?.row?.original?.id}`, {
                            state: {
                                content: item?.row?.original
                            }
                        })
                    }}>Edit</Button>
                    <ConfirmButton
                        variant={'danger'}
                        onOk={() => {
                            const contentUrl = config.CONTENT_API_URL;
                            const apiCore = new APICore();
                            return apiCore.delete(contentUrl + serviceEndpoints.contentDelete.replace(':id', item?.row?.original?.id)).then(() => {
                                dispatch(getContents())
                            }).catch(err => {
                                console.log('error')
                            })
                        }}
                        size={'sm'}>Delete</ConfirmButton>

                </div>
            }
        }
    ];


    useEffect(() => {
        dispatch(getContents())
    }, [])

    const {data, loading, error} = useSelector(
        (state: RootState) => state.Content.contents
    );

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: "", path: "/contents"},
                ]}
                title={"Contents"}
            />

            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col className={'d-flex align-content-end justify-content-end gap-2'}><Button variant={'dark'} onClick={() => {
                                    navigate('/contents/sort')
                                }}>Sort Contents</Button><Button onClick={() => {
                                    navigate('/contents/create')
                                }}>Add New Content</Button></Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <h4 className="header-title">Content List</h4>
                            <p className="text-muted fs-14 mb-4">
                                A simple example of table with pagination and column sorting
                            </p>

                            <Table
                                isSearchable={true}
                                columns={columns}
                                data={data || []}
                                pageSize={20}
                                isSortable={true}
                                pagination={true}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};